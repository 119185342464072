/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from '@aws-amplify/api';
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateAdminUser: OnCreateAdminUserSubscription;
  onUpdateAdminUser: OnUpdateAdminUserSubscription;
  onDeleteAdminUser: OnDeleteAdminUserSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateUserDetail: OnCreateUserDetailSubscription;
  onUpdateUserDetail: OnUpdateUserDetailSubscription;
  onDeleteUserDetail: OnDeleteUserDetailSubscription;
  onCreateAdapter: OnCreateAdapterSubscription;
  onUpdateAdapter: OnUpdateAdapterSubscription;
  onDeleteAdapter: OnDeleteAdapterSubscription;
  onCreateConnectionProfile: OnCreateConnectionProfileSubscription;
  onUpdateConnectionProfile: OnUpdateConnectionProfileSubscription;
  onDeleteConnectionProfile: OnDeleteConnectionProfileSubscription;
  onCreateAuditLog: OnCreateAuditLogSubscription;
  onUpdateAuditLog: OnUpdateAuditLogSubscription;
  onDeleteAuditLog: OnDeleteAuditLogSubscription;
  onCreateIntegrationType: OnCreateIntegrationTypeSubscription;
  onUpdateIntegrationType: OnUpdateIntegrationTypeSubscription;
  onDeleteIntegrationType: OnDeleteIntegrationTypeSubscription;
  onCreateEnvironment: OnCreateEnvironmentSubscription;
  onUpdateEnvironment: OnUpdateEnvironmentSubscription;
  onDeleteEnvironment: OnDeleteEnvironmentSubscription;
};

export type CreateAdminUserInput = {
  id?: string | null;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
};

export type ModelAdminUserConditionInput = {
  envPrefix?: ModelStringInput | null;
  name?: ModelStringInput | null;
  username?: ModelStringInput | null;
  emailAddress?: ModelStringInput | null;
  role?: ModelStringInput | null;
  hasKeys?: ModelBooleanInput | null;
  and?: Array<ModelAdminUserConditionInput | null> | null;
  or?: Array<ModelAdminUserConditionInput | null> | null;
  not?: ModelAdminUserConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type AdminUser = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAdminUserInput = {
  id: string;
  envPrefix?: string | null;
  name?: string | null;
  username?: string | null;
  emailAddress?: string | null;
  role?: string | null;
  hasKeys?: boolean | null;
};

export type DeleteAdminUserInput = {
  id: string;
};

export type CreateUserInput = {
  id?: string | null;
  emailAddress: string;
  role: string;
  userUserDetailId: string;
};

export type ModelUserConditionInput = {
  role?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type User = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: UserDetail;
  createdAt: string;
  updatedAt: string;
};

export type UserDetail = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: ModelConnectionProfileConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelConnectionProfileConnection = {
  __typename: "ModelConnectionProfileConnection";
  items: Array<ConnectionProfile | null>;
  nextToken?: string | null;
};

export type ConnectionProfile = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: UserDetail;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateUserInput = {
  id: string;
  emailAddress?: string | null;
  role?: string | null;
  userUserDetailId?: string | null;
};

export type DeleteUserInput = {
  id: string;
};

export type CreateUserDetailInput = {
  id?: string | null;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
};

export type ModelUserDetailConditionInput = {
  envPrefix?: ModelStringInput | null;
  name?: ModelStringInput | null;
  username?: ModelStringInput | null;
  hasKeys?: ModelBooleanInput | null;
  and?: Array<ModelUserDetailConditionInput | null> | null;
  or?: Array<ModelUserDetailConditionInput | null> | null;
  not?: ModelUserDetailConditionInput | null;
};

export type UpdateUserDetailInput = {
  id: string;
  ownerEmail?: string | null;
  envPrefix?: string | null;
  name?: string | null;
  username?: string | null;
  hasKeys?: boolean | null;
};

export type DeleteUserDetailInput = {
  id: string;
};

export type CreateAdapterInput = {
  id?: string | null;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
};

export type ModelAdapterConditionInput = {
  adapterType?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  endpoint?: ModelStringInput | null;
  envPrefix?: ModelStringInput | null;
  logoFilename?: ModelStringInput | null;
  logoHeight?: ModelStringInput | null;
  logoWidth?: ModelStringInput | null;
  lastSuccessfulPing?: ModelIntInput | null;
  localPortForwardPort?: ModelIntInput | null;
  and?: Array<ModelAdapterConditionInput | null> | null;
  or?: Array<ModelAdapterConditionInput | null> | null;
  not?: ModelAdapterConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Adapter = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateAdapterInput = {
  id: string;
  adapterType?: string | null;
  enabled?: boolean | null;
  name?: string | null;
  endpoint?: string | null;
  envPrefix?: string | null;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
};

export type DeleteAdapterInput = {
  id: string;
};

export type CreateConnectionProfileInput = {
  id?: string | null;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  connectionProfileUserDetailId: string;
};

export type ModelConnectionProfileConditionInput = {
  name?: ModelStringInput | null;
  adapterId?: ModelStringInput | null;
  envPrefix?: ModelStringInput | null;
  accountId?: ModelStringInput | null;
  environment?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  audience?: ModelStringInput | null;
  authKey?: ModelStringInput | null;
  ipUrl?: ModelStringInput | null;
  purpose?: ModelStringInput | null;
  isRegistered?: ModelBooleanInput | null;
  jwtToken?: ModelStringInput | null;
  and?: Array<ModelConnectionProfileConditionInput | null> | null;
  or?: Array<ModelConnectionProfileConditionInput | null> | null;
  not?: ModelConnectionProfileConditionInput | null;
};

export type UpdateConnectionProfileInput = {
  id: string;
  name?: string | null;
  adapterId?: string | null;
  envPrefix?: string | null;
  accountId?: string | null;
  environment?: string | null;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  connectionProfileUserDetailId?: string | null;
};

export type DeleteConnectionProfileInput = {
  id: string;
};

export type CreateAuditLogInput = {
  id?: string | null;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
};

export type ModelAuditLogConditionInput = {
  envPrefix?: ModelStringInput | null;
  logTs?: ModelIntInput | null;
  userName?: ModelStringInput | null;
  adapterType?: ModelStringInput | null;
  integration?: ModelStringInput | null;
  action?: ModelStringInput | null;
  message?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  and?: Array<ModelAuditLogConditionInput | null> | null;
  or?: Array<ModelAuditLogConditionInput | null> | null;
  not?: ModelAuditLogConditionInput | null;
};

export type AuditLog = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAuditLogInput = {
  id: string;
  envPrefix?: string | null;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
};

export type DeleteAuditLogInput = {
  id: string;
};

export type CreateIntegrationTypeInput = {
  id?: string | null;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
};

export type ModelIntegrationTypeConditionInput = {
  code?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  logoFilename?: ModelStringInput | null;
  logoHeight?: ModelStringInput | null;
  logoWidth?: ModelStringInput | null;
  and?: Array<ModelIntegrationTypeConditionInput | null> | null;
  or?: Array<ModelIntegrationTypeConditionInput | null> | null;
  not?: ModelIntegrationTypeConditionInput | null;
};

export type IntegrationType = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIntegrationTypeInput = {
  id: string;
  code?: string | null;
  displayName?: string | null;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
};

export type DeleteIntegrationTypeInput = {
  id: string;
};

export type CreateEnvironmentInput = {
  id?: string | null;
  name: string;
  url: string;
  reference: string;
};

export type ModelEnvironmentConditionInput = {
  name?: ModelStringInput | null;
  url?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  and?: Array<ModelEnvironmentConditionInput | null> | null;
  or?: Array<ModelEnvironmentConditionInput | null> | null;
  not?: ModelEnvironmentConditionInput | null;
};

export type Environment = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEnvironmentInput = {
  id: string;
  name?: string | null;
  url?: string | null;
  reference?: string | null;
};

export type DeleteEnvironmentInput = {
  id: string;
};

export type ModelAdminUserFilterInput = {
  id?: ModelIDInput | null;
  envPrefix?: ModelStringInput | null;
  name?: ModelStringInput | null;
  username?: ModelStringInput | null;
  emailAddress?: ModelStringInput | null;
  role?: ModelStringInput | null;
  hasKeys?: ModelBooleanInput | null;
  and?: Array<ModelAdminUserFilterInput | null> | null;
  or?: Array<ModelAdminUserFilterInput | null> | null;
  not?: ModelAdminUserFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelAdminUserConnection = {
  __typename: "ModelAdminUserConnection";
  items: Array<AdminUser | null>;
  nextToken?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  emailAddress?: ModelStringInput | null;
  role?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type ModelUserDetailFilterInput = {
  id?: ModelIDInput | null;
  ownerEmail?: ModelStringInput | null;
  envPrefix?: ModelStringInput | null;
  name?: ModelStringInput | null;
  username?: ModelStringInput | null;
  hasKeys?: ModelBooleanInput | null;
  and?: Array<ModelUserDetailFilterInput | null> | null;
  or?: Array<ModelUserDetailFilterInput | null> | null;
  not?: ModelUserDetailFilterInput | null;
};

export type ModelUserDetailConnection = {
  __typename: "ModelUserDetailConnection";
  items: Array<UserDetail | null>;
  nextToken?: string | null;
};

export type ModelAdapterFilterInput = {
  id?: ModelIDInput | null;
  adapterType?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  endpoint?: ModelStringInput | null;
  envPrefix?: ModelStringInput | null;
  logoFilename?: ModelStringInput | null;
  logoHeight?: ModelStringInput | null;
  logoWidth?: ModelStringInput | null;
  lastSuccessfulPing?: ModelIntInput | null;
  localPortForwardPort?: ModelIntInput | null;
  and?: Array<ModelAdapterFilterInput | null> | null;
  or?: Array<ModelAdapterFilterInput | null> | null;
  not?: ModelAdapterFilterInput | null;
};

export type ModelAdapterConnection = {
  __typename: "ModelAdapterConnection";
  items: Array<Adapter | null>;
  nextToken?: string | null;
};

export type ModelConnectionProfileFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  adapterId?: ModelStringInput | null;
  envPrefix?: ModelStringInput | null;
  accountId?: ModelStringInput | null;
  environment?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  audience?: ModelStringInput | null;
  authKey?: ModelStringInput | null;
  ipUrl?: ModelStringInput | null;
  purpose?: ModelStringInput | null;
  isRegistered?: ModelBooleanInput | null;
  jwtToken?: ModelStringInput | null;
  and?: Array<ModelConnectionProfileFilterInput | null> | null;
  or?: Array<ModelConnectionProfileFilterInput | null> | null;
  not?: ModelConnectionProfileFilterInput | null;
};

export type ModelAuditLogFilterInput = {
  id?: ModelIDInput | null;
  envPrefix?: ModelStringInput | null;
  logTs?: ModelIntInput | null;
  userName?: ModelStringInput | null;
  adapterType?: ModelStringInput | null;
  integration?: ModelStringInput | null;
  action?: ModelStringInput | null;
  message?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  and?: Array<ModelAuditLogFilterInput | null> | null;
  or?: Array<ModelAuditLogFilterInput | null> | null;
  not?: ModelAuditLogFilterInput | null;
};

export type ModelAuditLogConnection = {
  __typename: "ModelAuditLogConnection";
  items: Array<AuditLog | null>;
  nextToken?: string | null;
};

export type ModelIntegrationTypeFilterInput = {
  id?: ModelIDInput | null;
  code?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  logoFilename?: ModelStringInput | null;
  logoHeight?: ModelStringInput | null;
  logoWidth?: ModelStringInput | null;
  and?: Array<ModelIntegrationTypeFilterInput | null> | null;
  or?: Array<ModelIntegrationTypeFilterInput | null> | null;
  not?: ModelIntegrationTypeFilterInput | null;
};

export type ModelIntegrationTypeConnection = {
  __typename: "ModelIntegrationTypeConnection";
  items: Array<IntegrationType | null>;
  nextToken?: string | null;
};

export type ModelEnvironmentFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  url?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  and?: Array<ModelEnvironmentFilterInput | null> | null;
  or?: Array<ModelEnvironmentFilterInput | null> | null;
  not?: ModelEnvironmentFilterInput | null;
};

export type ModelEnvironmentConnection = {
  __typename: "ModelEnvironmentConnection";
  items: Array<Environment | null>;
  nextToken?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type CreateAdminUserMutation = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAdminUserMutation = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAdminUserMutation = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type CreateUserDetailMutation = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserDetailMutation = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserDetailMutation = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAdapterMutation = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateAdapterMutation = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type DeleteAdapterMutation = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type CreateConnectionProfileMutation = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateConnectionProfileMutation = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type DeleteConnectionProfileMutation = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type CreateAuditLogMutation = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAuditLogMutation = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAuditLogMutation = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateIntegrationTypeMutation = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIntegrationTypeMutation = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIntegrationTypeMutation = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateEnvironmentMutation = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEnvironmentMutation = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEnvironmentMutation = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type GetAdminUserQuery = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAdminUsersQuery = {
  __typename: "ModelAdminUserConnection";
  items: Array<{
    __typename: "AdminUser";
    id: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    emailAddress: string;
    role: string;
    hasKeys?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    emailAddress: string;
    role: string;
    userDetail: {
      __typename: "UserDetail";
      id: string;
      ownerEmail: string;
      envPrefix: string;
      name: string;
      username?: string | null;
      hasKeys?: boolean | null;
      connectionProfiles?: {
        __typename: "ModelConnectionProfileConnection";
        items: Array<{
          __typename: "ConnectionProfile";
          id: string;
          name: string;
          adapterId: string;
          envPrefix: string;
          accountId: string;
          environment: string;
          userName?: string | null;
          audience?: string | null;
          authKey?: string | null;
          ipUrl?: string | null;
          purpose?: string | null;
          isRegistered?: boolean | null;
          jwtToken?: string | null;
          userDetail: {
            __typename: "UserDetail";
            id: string;
            ownerEmail: string;
            envPrefix: string;
            name: string;
            username?: string | null;
            hasKeys?: boolean | null;
            connectionProfiles?: {
              __typename: "ModelConnectionProfileConnection";
              items: Array<{
                __typename: "ConnectionProfile";
                id: string;
                name: string;
                adapterId: string;
                envPrefix: string;
                accountId: string;
                environment: string;
                userName?: string | null;
                audience?: string | null;
                authKey?: string | null;
                ipUrl?: string | null;
                purpose?: string | null;
                isRegistered?: boolean | null;
                jwtToken?: string | null;
                createdAt: string;
                updatedAt: string;
                owner?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserDetailQuery = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUserDetailsQuery = {
  __typename: "ModelUserDetailConnection";
  items: Array<{
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAdapterQuery = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type ListAdaptersQuery = {
  __typename: "ModelAdapterConnection";
  items: Array<{
    __typename: "Adapter";
    id: string;
    adapterType: string;
    enabled: boolean;
    name: string;
    endpoint: string;
    envPrefix: string;
    logoFilename?: string | null;
    logoHeight?: string | null;
    logoWidth?: string | null;
    lastSuccessfulPing?: number | null;
    localPortForwardPort?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetConnectionProfileQuery = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type ListConnectionProfilesQuery = {
  __typename: "ModelConnectionProfileConnection";
  items: Array<{
    __typename: "ConnectionProfile";
    id: string;
    name: string;
    adapterId: string;
    envPrefix: string;
    accountId: string;
    environment: string;
    userName?: string | null;
    audience?: string | null;
    authKey?: string | null;
    ipUrl?: string | null;
    purpose?: string | null;
    isRegistered?: boolean | null;
    jwtToken?: string | null;
    userDetail: {
      __typename: "UserDetail";
      id: string;
      ownerEmail: string;
      envPrefix: string;
      name: string;
      username?: string | null;
      hasKeys?: boolean | null;
      connectionProfiles?: {
        __typename: "ModelConnectionProfileConnection";
        items: Array<{
          __typename: "ConnectionProfile";
          id: string;
          name: string;
          adapterId: string;
          envPrefix: string;
          accountId: string;
          environment: string;
          userName?: string | null;
          audience?: string | null;
          authKey?: string | null;
          ipUrl?: string | null;
          purpose?: string | null;
          isRegistered?: boolean | null;
          jwtToken?: string | null;
          userDetail: {
            __typename: "UserDetail";
            id: string;
            ownerEmail: string;
            envPrefix: string;
            name: string;
            username?: string | null;
            hasKeys?: boolean | null;
            connectionProfiles?: {
              __typename: "ModelConnectionProfileConnection";
              items: Array<{
                __typename: "ConnectionProfile";
                id: string;
                name: string;
                adapterId: string;
                envPrefix: string;
                accountId: string;
                environment: string;
                userName?: string | null;
                audience?: string | null;
                authKey?: string | null;
                ipUrl?: string | null;
                purpose?: string | null;
                isRegistered?: boolean | null;
                jwtToken?: string | null;
                createdAt: string;
                updatedAt: string;
                owner?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetAuditLogQuery = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAuditLogsQuery = {
  __typename: "ModelAuditLogConnection";
  items: Array<{
    __typename: "AuditLog";
    id: string;
    envPrefix: string;
    logTs?: number | null;
    userName?: string | null;
    adapterType?: string | null;
    integration?: string | null;
    action?: string | null;
    message?: string | null;
    payload?: string | null;
    expirationUnixTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIntegrationTypeQuery = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListIntegrationTypesQuery = {
  __typename: "ModelIntegrationTypeConnection";
  items: Array<{
    __typename: "IntegrationType";
    id: string;
    code: string;
    displayName: string;
    logoFilename?: string | null;
    logoHeight?: string | null;
    logoWidth?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEnvironmentQuery = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type ListEnvironmentsQuery = {
  __typename: "ModelEnvironmentConnection";
  items: Array<{
    __typename: "Environment";
    id: string;
    name: string;
    url: string;
    reference: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type LogsByEnvQuery = {
  __typename: "ModelAuditLogConnection";
  items: Array<{
    __typename: "AuditLog";
    id: string;
    envPrefix: string;
    logTs?: number | null;
    userName?: string | null;
    adapterType?: string | null;
    integration?: string | null;
    action?: string | null;
    message?: string | null;
    payload?: string | null;
    expirationUnixTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateAdminUserSubscription = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAdminUserSubscription = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAdminUserSubscription = {
  __typename: "AdminUser";
  id: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  emailAddress: string;
  role: string;
  hasKeys?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  emailAddress: string;
  role: string;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserDetailSubscription = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserDetailSubscription = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserDetailSubscription = {
  __typename: "UserDetail";
  id: string;
  ownerEmail: string;
  envPrefix: string;
  name: string;
  username?: string | null;
  hasKeys?: boolean | null;
  connectionProfiles?: {
    __typename: "ModelConnectionProfileConnection";
    items: Array<{
      __typename: "ConnectionProfile";
      id: string;
      name: string;
      adapterId: string;
      envPrefix: string;
      accountId: string;
      environment: string;
      userName?: string | null;
      audience?: string | null;
      authKey?: string | null;
      ipUrl?: string | null;
      purpose?: string | null;
      isRegistered?: boolean | null;
      jwtToken?: string | null;
      userDetail: {
        __typename: "UserDetail";
        id: string;
        ownerEmail: string;
        envPrefix: string;
        name: string;
        username?: string | null;
        hasKeys?: boolean | null;
        connectionProfiles?: {
          __typename: "ModelConnectionProfileConnection";
          items: Array<{
            __typename: "ConnectionProfile";
            id: string;
            name: string;
            adapterId: string;
            envPrefix: string;
            accountId: string;
            environment: string;
            userName?: string | null;
            audience?: string | null;
            authKey?: string | null;
            ipUrl?: string | null;
            purpose?: string | null;
            isRegistered?: boolean | null;
            jwtToken?: string | null;
            userDetail: {
              __typename: "UserDetail";
              id: string;
              ownerEmail: string;
              envPrefix: string;
              name: string;
              username?: string | null;
              hasKeys?: boolean | null;
              connectionProfiles?: {
                __typename: "ModelConnectionProfileConnection";
                nextToken?: string | null;
              } | null;
              createdAt: string;
              updatedAt: string;
            };
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAdapterSubscription = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnUpdateAdapterSubscription = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnDeleteAdapterSubscription = {
  __typename: "Adapter";
  id: string;
  adapterType: string;
  enabled: boolean;
  name: string;
  endpoint: string;
  envPrefix: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  lastSuccessfulPing?: number | null;
  localPortForwardPort?: number | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnCreateConnectionProfileSubscription = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnUpdateConnectionProfileSubscription = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnDeleteConnectionProfileSubscription = {
  __typename: "ConnectionProfile";
  id: string;
  name: string;
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName?: string | null;
  audience?: string | null;
  authKey?: string | null;
  ipUrl?: string | null;
  purpose?: string | null;
  isRegistered?: boolean | null;
  jwtToken?: string | null;
  userDetail: {
    __typename: "UserDetail";
    id: string;
    ownerEmail: string;
    envPrefix: string;
    name: string;
    username?: string | null;
    hasKeys?: boolean | null;
    connectionProfiles?: {
      __typename: "ModelConnectionProfileConnection";
      items: Array<{
        __typename: "ConnectionProfile";
        id: string;
        name: string;
        adapterId: string;
        envPrefix: string;
        accountId: string;
        environment: string;
        userName?: string | null;
        audience?: string | null;
        authKey?: string | null;
        ipUrl?: string | null;
        purpose?: string | null;
        isRegistered?: boolean | null;
        jwtToken?: string | null;
        userDetail: {
          __typename: "UserDetail";
          id: string;
          ownerEmail: string;
          envPrefix: string;
          name: string;
          username?: string | null;
          hasKeys?: boolean | null;
          connectionProfiles?: {
            __typename: "ModelConnectionProfileConnection";
            items: Array<{
              __typename: "ConnectionProfile";
              id: string;
              name: string;
              adapterId: string;
              envPrefix: string;
              accountId: string;
              environment: string;
              userName?: string | null;
              audience?: string | null;
              authKey?: string | null;
              ipUrl?: string | null;
              purpose?: string | null;
              isRegistered?: boolean | null;
              jwtToken?: string | null;
              userDetail: {
                __typename: "UserDetail";
                id: string;
                ownerEmail: string;
                envPrefix: string;
                name: string;
                username?: string | null;
                hasKeys?: boolean | null;
                createdAt: string;
                updatedAt: string;
              };
              createdAt: string;
              updatedAt: string;
              owner?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnCreateAuditLogSubscription = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAuditLogSubscription = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAuditLogSubscription = {
  __typename: "AuditLog";
  id: string;
  envPrefix: string;
  logTs?: number | null;
  userName?: string | null;
  adapterType?: string | null;
  integration?: string | null;
  action?: string | null;
  message?: string | null;
  payload?: string | null;
  expirationUnixTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIntegrationTypeSubscription = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIntegrationTypeSubscription = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIntegrationTypeSubscription = {
  __typename: "IntegrationType";
  id: string;
  code: string;
  displayName: string;
  logoFilename?: string | null;
  logoHeight?: string | null;
  logoWidth?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEnvironmentSubscription = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEnvironmentSubscription = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEnvironmentSubscription = {
  __typename: "Environment";
  id: string;
  name: string;
  url: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateAdminUser(
    input: CreateAdminUserInput,
    condition?: ModelAdminUserConditionInput
  ): Promise<CreateAdminUserMutation> {
    const statement = `mutation CreateAdminUser($input: CreateAdminUserInput!, $condition: ModelAdminUserConditionInput) {
        createAdminUser(input: $input, condition: $condition) {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAdminUserMutation>response.data.createAdminUser;
  }
  async UpdateAdminUser(
    input: UpdateAdminUserInput,
    condition?: ModelAdminUserConditionInput
  ): Promise<UpdateAdminUserMutation> {
    const statement = `mutation UpdateAdminUser($input: UpdateAdminUserInput!, $condition: ModelAdminUserConditionInput) {
        updateAdminUser(input: $input, condition: $condition) {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAdminUserMutation>response.data.updateAdminUser;
  }
  async DeleteAdminUser(
    input: DeleteAdminUserInput,
    condition?: ModelAdminUserConditionInput
  ): Promise<DeleteAdminUserMutation> {
    const statement = `mutation DeleteAdminUser($input: DeleteAdminUserInput!, $condition: ModelAdminUserConditionInput) {
        deleteAdminUser(input: $input, condition: $condition) {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAdminUserMutation>response.data.deleteAdminUser;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateUserDetail(
    input: CreateUserDetailInput,
    condition?: ModelUserDetailConditionInput
  ): Promise<CreateUserDetailMutation> {
    const statement = `mutation CreateUserDetail($input: CreateUserDetailInput!, $condition: ModelUserDetailConditionInput) {
        createUserDetail(input: $input, condition: $condition) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserDetailMutation>response.data.createUserDetail;
  }
  async UpdateUserDetail(
    input: UpdateUserDetailInput,
    condition?: ModelUserDetailConditionInput
  ): Promise<UpdateUserDetailMutation> {
    const statement = `mutation UpdateUserDetail($input: UpdateUserDetailInput!, $condition: ModelUserDetailConditionInput) {
        updateUserDetail(input: $input, condition: $condition) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserDetailMutation>response.data.updateUserDetail;
  }
  async DeleteUserDetail(
    input: DeleteUserDetailInput,
    condition?: ModelUserDetailConditionInput
  ): Promise<DeleteUserDetailMutation> {
    const statement = `mutation DeleteUserDetail($input: DeleteUserDetailInput!, $condition: ModelUserDetailConditionInput) {
        deleteUserDetail(input: $input, condition: $condition) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserDetailMutation>response.data.deleteUserDetail;
  }
  async CreateAdapter(
    input: CreateAdapterInput,
    condition?: ModelAdapterConditionInput
  ): Promise<CreateAdapterMutation> {
    const statement = `mutation CreateAdapter($input: CreateAdapterInput!, $condition: ModelAdapterConditionInput) {
        createAdapter(input: $input, condition: $condition) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAdapterMutation>response.data.createAdapter;
  }
  async UpdateAdapter(
    input: UpdateAdapterInput,
    condition?: ModelAdapterConditionInput
  ): Promise<UpdateAdapterMutation> {
    const statement = `mutation UpdateAdapter($input: UpdateAdapterInput!, $condition: ModelAdapterConditionInput) {
        updateAdapter(input: $input, condition: $condition) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAdapterMutation>response.data.updateAdapter;
  }
  async DeleteAdapter(
    input: DeleteAdapterInput,
    condition?: ModelAdapterConditionInput
  ): Promise<DeleteAdapterMutation> {
    const statement = `mutation DeleteAdapter($input: DeleteAdapterInput!, $condition: ModelAdapterConditionInput) {
        deleteAdapter(input: $input, condition: $condition) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAdapterMutation>response.data.deleteAdapter;
  }
  async CreateConnectionProfile(
    input: CreateConnectionProfileInput,
    condition?: ModelConnectionProfileConditionInput
  ): Promise<CreateConnectionProfileMutation> {
    const statement = `mutation CreateConnectionProfile($input: CreateConnectionProfileInput!, $condition: ModelConnectionProfileConditionInput) {
        createConnectionProfile(input: $input, condition: $condition) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConnectionProfileMutation>(
      response.data.createConnectionProfile
    );
  }
  async UpdateConnectionProfile(
    input: UpdateConnectionProfileInput,
    condition?: ModelConnectionProfileConditionInput
  ): Promise<UpdateConnectionProfileMutation> {
    const statement = `mutation UpdateConnectionProfile($input: UpdateConnectionProfileInput!, $condition: ModelConnectionProfileConditionInput) {
        updateConnectionProfile(input: $input, condition: $condition) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConnectionProfileMutation>(
      response.data.updateConnectionProfile
    );
  }
  async DeleteConnectionProfile(
    input: DeleteConnectionProfileInput,
    condition?: ModelConnectionProfileConditionInput
  ): Promise<DeleteConnectionProfileMutation> {
    const statement = `mutation DeleteConnectionProfile($input: DeleteConnectionProfileInput!, $condition: ModelConnectionProfileConditionInput) {
        deleteConnectionProfile(input: $input, condition: $condition) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConnectionProfileMutation>(
      response.data.deleteConnectionProfile
    );
  }
  async CreateAuditLog(
    input: CreateAuditLogInput,
    condition?: ModelAuditLogConditionInput
  ): Promise<CreateAuditLogMutation> {
    const statement = `mutation CreateAuditLog($input: CreateAuditLogInput!, $condition: ModelAuditLogConditionInput) {
        createAuditLog(input: $input, condition: $condition) {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAuditLogMutation>response.data.createAuditLog;
  }
  async UpdateAuditLog(
    input: UpdateAuditLogInput,
    condition?: ModelAuditLogConditionInput
  ): Promise<UpdateAuditLogMutation> {
    const statement = `mutation UpdateAuditLog($input: UpdateAuditLogInput!, $condition: ModelAuditLogConditionInput) {
        updateAuditLog(input: $input, condition: $condition) {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAuditLogMutation>response.data.updateAuditLog;
  }
  async DeleteAuditLog(
    input: DeleteAuditLogInput,
    condition?: ModelAuditLogConditionInput
  ): Promise<DeleteAuditLogMutation> {
    const statement = `mutation DeleteAuditLog($input: DeleteAuditLogInput!, $condition: ModelAuditLogConditionInput) {
        deleteAuditLog(input: $input, condition: $condition) {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAuditLogMutation>response.data.deleteAuditLog;
  }
  async CreateIntegrationType(
    input: CreateIntegrationTypeInput,
    condition?: ModelIntegrationTypeConditionInput
  ): Promise<CreateIntegrationTypeMutation> {
    const statement = `mutation CreateIntegrationType($input: CreateIntegrationTypeInput!, $condition: ModelIntegrationTypeConditionInput) {
        createIntegrationType(input: $input, condition: $condition) {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIntegrationTypeMutation>response.data.createIntegrationType;
  }
  async UpdateIntegrationType(
    input: UpdateIntegrationTypeInput,
    condition?: ModelIntegrationTypeConditionInput
  ): Promise<UpdateIntegrationTypeMutation> {
    const statement = `mutation UpdateIntegrationType($input: UpdateIntegrationTypeInput!, $condition: ModelIntegrationTypeConditionInput) {
        updateIntegrationType(input: $input, condition: $condition) {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIntegrationTypeMutation>response.data.updateIntegrationType;
  }
  async DeleteIntegrationType(
    input: DeleteIntegrationTypeInput,
    condition?: ModelIntegrationTypeConditionInput
  ): Promise<DeleteIntegrationTypeMutation> {
    const statement = `mutation DeleteIntegrationType($input: DeleteIntegrationTypeInput!, $condition: ModelIntegrationTypeConditionInput) {
        deleteIntegrationType(input: $input, condition: $condition) {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIntegrationTypeMutation>response.data.deleteIntegrationType;
  }
  async CreateEnvironment(
    input: CreateEnvironmentInput,
    condition?: ModelEnvironmentConditionInput
  ): Promise<CreateEnvironmentMutation> {
    const statement = `mutation CreateEnvironment($input: CreateEnvironmentInput!, $condition: ModelEnvironmentConditionInput) {
        createEnvironment(input: $input, condition: $condition) {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEnvironmentMutation>response.data.createEnvironment;
  }
  async UpdateEnvironment(
    input: UpdateEnvironmentInput,
    condition?: ModelEnvironmentConditionInput
  ): Promise<UpdateEnvironmentMutation> {
    const statement = `mutation UpdateEnvironment($input: UpdateEnvironmentInput!, $condition: ModelEnvironmentConditionInput) {
        updateEnvironment(input: $input, condition: $condition) {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEnvironmentMutation>response.data.updateEnvironment;
  }
  async DeleteEnvironment(
    input: DeleteEnvironmentInput,
    condition?: ModelEnvironmentConditionInput
  ): Promise<DeleteEnvironmentMutation> {
    const statement = `mutation DeleteEnvironment($input: DeleteEnvironmentInput!, $condition: ModelEnvironmentConditionInput) {
        deleteEnvironment(input: $input, condition: $condition) {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEnvironmentMutation>response.data.deleteEnvironment;
  }
  async GetAdminUser(id: string): Promise<GetAdminUserQuery> {
    const statement = `query GetAdminUser($id: ID!) {
        getAdminUser(id: $id) {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAdminUserQuery>response.data.getAdminUser;
  }
  async ListAdminUsers(
    filter?: ModelAdminUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAdminUsersQuery> {
    const statement = `query ListAdminUsers($filter: ModelAdminUserFilterInput, $limit: Int, $nextToken: String) {
        listAdminUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            envPrefix
            name
            username
            emailAddress
            role
            hasKeys
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAdminUsersQuery>response.data.listAdminUsers;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            emailAddress
            role
            userDetail {
              __typename
              id
              ownerEmail
              envPrefix
              name
              username
              hasKeys
              connectionProfiles {
                __typename
                items {
                  __typename
                  id
                  name
                  adapterId
                  envPrefix
                  accountId
                  environment
                  userName
                  audience
                  authKey
                  ipUrl
                  purpose
                  isRegistered
                  jwtToken
                  userDetail {
                    __typename
                    id
                    ownerEmail
                    envPrefix
                    name
                    username
                    hasKeys
                    connectionProfiles {
                      __typename
                      items {
                        __typename
                        id
                        name
                        adapterId
                        envPrefix
                        accountId
                        environment
                        userName
                        audience
                        authKey
                        ipUrl
                        purpose
                        isRegistered
                        jwtToken
                        createdAt
                        updatedAt
                        owner
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetUserDetail(id: string): Promise<GetUserDetailQuery> {
    const statement = `query GetUserDetail($id: ID!) {
        getUserDetail(id: $id) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserDetailQuery>response.data.getUserDetail;
  }
  async ListUserDetails(
    filter?: ModelUserDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserDetailsQuery> {
    const statement = `query ListUserDetails($filter: ModelUserDetailFilterInput, $limit: Int, $nextToken: String) {
        listUserDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserDetailsQuery>response.data.listUserDetails;
  }
  async GetAdapter(id: string): Promise<GetAdapterQuery> {
    const statement = `query GetAdapter($id: ID!) {
        getAdapter(id: $id) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAdapterQuery>response.data.getAdapter;
  }
  async ListAdapters(
    filter?: ModelAdapterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAdaptersQuery> {
    const statement = `query ListAdapters($filter: ModelAdapterFilterInput, $limit: Int, $nextToken: String) {
        listAdapters(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            adapterType
            enabled
            name
            endpoint
            envPrefix
            logoFilename
            logoHeight
            logoWidth
            lastSuccessfulPing
            localPortForwardPort
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAdaptersQuery>response.data.listAdapters;
  }
  async GetConnectionProfile(id: string): Promise<GetConnectionProfileQuery> {
    const statement = `query GetConnectionProfile($id: ID!) {
        getConnectionProfile(id: $id) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConnectionProfileQuery>response.data.getConnectionProfile;
  }
  async ListConnectionProfiles(
    filter?: ModelConnectionProfileFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConnectionProfilesQuery> {
    const statement = `query ListConnectionProfiles($filter: ModelConnectionProfileFilterInput, $limit: Int, $nextToken: String) {
        listConnectionProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            adapterId
            envPrefix
            accountId
            environment
            userName
            audience
            authKey
            ipUrl
            purpose
            isRegistered
            jwtToken
            userDetail {
              __typename
              id
              ownerEmail
              envPrefix
              name
              username
              hasKeys
              connectionProfiles {
                __typename
                items {
                  __typename
                  id
                  name
                  adapterId
                  envPrefix
                  accountId
                  environment
                  userName
                  audience
                  authKey
                  ipUrl
                  purpose
                  isRegistered
                  jwtToken
                  userDetail {
                    __typename
                    id
                    ownerEmail
                    envPrefix
                    name
                    username
                    hasKeys
                    connectionProfiles {
                      __typename
                      items {
                        __typename
                        id
                        name
                        adapterId
                        envPrefix
                        accountId
                        environment
                        userName
                        audience
                        authKey
                        ipUrl
                        purpose
                        isRegistered
                        jwtToken
                        createdAt
                        updatedAt
                        owner
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConnectionProfilesQuery>response.data.listConnectionProfiles;
  }
  async GetAuditLog(id: string): Promise<GetAuditLogQuery> {
    const statement = `query GetAuditLog($id: ID!) {
        getAuditLog(id: $id) {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAuditLogQuery>response.data.getAuditLog;
  }
  async ListAuditLogs(
    filter?: ModelAuditLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAuditLogsQuery> {
    const statement = `query ListAuditLogs($filter: ModelAuditLogFilterInput, $limit: Int, $nextToken: String) {
        listAuditLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            envPrefix
            logTs
            userName
            adapterType
            integration
            action
            message
            payload
            expirationUnixTime
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAuditLogsQuery>response.data.listAuditLogs;
  }
  async GetIntegrationType(id: string): Promise<GetIntegrationTypeQuery> {
    const statement = `query GetIntegrationType($id: ID!) {
        getIntegrationType(id: $id) {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIntegrationTypeQuery>response.data.getIntegrationType;
  }
  async ListIntegrationTypes(
    filter?: ModelIntegrationTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntegrationTypesQuery> {
    const statement = `query ListIntegrationTypes($filter: ModelIntegrationTypeFilterInput, $limit: Int, $nextToken: String) {
        listIntegrationTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            code
            displayName
            logoFilename
            logoHeight
            logoWidth
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIntegrationTypesQuery>response.data.listIntegrationTypes;
  }
  async GetEnvironment(id: string): Promise<GetEnvironmentQuery> {
    const statement = `query GetEnvironment($id: ID!) {
        getEnvironment(id: $id) {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEnvironmentQuery>response.data.getEnvironment;
  }
  async ListEnvironments(
    filter?: ModelEnvironmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEnvironmentsQuery> {
    const statement = `query ListEnvironments($filter: ModelEnvironmentFilterInput, $limit: Int, $nextToken: String) {
        listEnvironments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            url
            reference
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEnvironmentsQuery>response.data.listEnvironments;
  }
  async LogsByEnv(
    envPrefix?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuditLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByEnvQuery> {
    const statement = `query LogsByEnv($envPrefix: String, $sortDirection: ModelSortDirection, $filter: ModelAuditLogFilterInput, $limit: Int, $nextToken: String) {
        logsByEnv(envPrefix: $envPrefix, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            envPrefix
            logTs
            userName
            adapterType
            integration
            action
            message
            payload
            expirationUnixTime
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (envPrefix) {
      gqlAPIServiceArguments.envPrefix = envPrefix;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LogsByEnvQuery>response.data.logsByEnv;
  }
  OnCreateAdminUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAdminUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAdminUser {
        onCreateAdminUser {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAdminUser">>
  >;

  OnUpdateAdminUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAdminUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAdminUser {
        onUpdateAdminUser {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAdminUser">>
  >;

  OnDeleteAdminUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAdminUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAdminUser {
        onDeleteAdminUser {
          __typename
          id
          envPrefix
          name
          username
          emailAddress
          role
          hasKeys
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAdminUser">>
  >;

  OnCreateUserListener(
    emailAddress?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > {
    const statement = `subscription OnCreateUser($emailAddress: String) {
        onCreateUser(emailAddress: $emailAddress) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (emailAddress) {
      gqlAPIServiceArguments.emailAddress = emailAddress;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
    >;
  }

  OnUpdateUserListener(
    emailAddress?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > {
    const statement = `subscription OnUpdateUser($emailAddress: String) {
        onUpdateUser(emailAddress: $emailAddress) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (emailAddress) {
      gqlAPIServiceArguments.emailAddress = emailAddress;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
    >;
  }

  OnDeleteUserListener(
    emailAddress?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > {
    const statement = `subscription OnDeleteUser($emailAddress: String) {
        onDeleteUser(emailAddress: $emailAddress) {
          __typename
          id
          emailAddress
          role
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (emailAddress) {
      gqlAPIServiceArguments.emailAddress = emailAddress;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
    >;
  }

  OnCreateUserDetailListener(
    ownerEmail?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserDetail">>
  > {
    const statement = `subscription OnCreateUserDetail($ownerEmail: String) {
        onCreateUserDetail(ownerEmail: $ownerEmail) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (ownerEmail) {
      gqlAPIServiceArguments.ownerEmail = ownerEmail;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserDetail">>
    >;
  }

  OnUpdateUserDetailListener(
    ownerEmail?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserDetail">>
  > {
    const statement = `subscription OnUpdateUserDetail($ownerEmail: String) {
        onUpdateUserDetail(ownerEmail: $ownerEmail) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (ownerEmail) {
      gqlAPIServiceArguments.ownerEmail = ownerEmail;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserDetail">>
    >;
  }

  OnDeleteUserDetailListener(
    ownerEmail?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserDetail">>
  > {
    const statement = `subscription OnDeleteUserDetail($ownerEmail: String) {
        onDeleteUserDetail(ownerEmail: $ownerEmail) {
          __typename
          id
          ownerEmail
          envPrefix
          name
          username
          hasKeys
          connectionProfiles {
            __typename
            items {
              __typename
              id
              name
              adapterId
              envPrefix
              accountId
              environment
              userName
              audience
              authKey
              ipUrl
              purpose
              isRegistered
              jwtToken
              userDetail {
                __typename
                id
                ownerEmail
                envPrefix
                name
                username
                hasKeys
                connectionProfiles {
                  __typename
                  items {
                    __typename
                    id
                    name
                    adapterId
                    envPrefix
                    accountId
                    environment
                    userName
                    audience
                    authKey
                    ipUrl
                    purpose
                    isRegistered
                    jwtToken
                    userDetail {
                      __typename
                      id
                      ownerEmail
                      envPrefix
                      name
                      username
                      hasKeys
                      connectionProfiles {
                        __typename
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (ownerEmail) {
      gqlAPIServiceArguments.ownerEmail = ownerEmail;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserDetail">>
    >;
  }

  OnCreateAdapterListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAdapter">>
  > {
    const statement = `subscription OnCreateAdapter($owner: String) {
        onCreateAdapter(owner: $owner) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAdapter">>
    >;
  }

  OnUpdateAdapterListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAdapter">>
  > {
    const statement = `subscription OnUpdateAdapter($owner: String) {
        onUpdateAdapter(owner: $owner) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAdapter">>
    >;
  }

  OnDeleteAdapterListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAdapter">>
  > {
    const statement = `subscription OnDeleteAdapter($owner: String) {
        onDeleteAdapter(owner: $owner) {
          __typename
          id
          adapterType
          enabled
          name
          endpoint
          envPrefix
          logoFilename
          logoHeight
          logoWidth
          lastSuccessfulPing
          localPortForwardPort
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAdapter">>
    >;
  }

  OnCreateConnectionProfileListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConnectionProfile">
    >
  > {
    const statement = `subscription OnCreateConnectionProfile($owner: String) {
        onCreateConnectionProfile(owner: $owner) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateConnectionProfile">
      >
    >;
  }

  OnUpdateConnectionProfileListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConnectionProfile">
    >
  > {
    const statement = `subscription OnUpdateConnectionProfile($owner: String) {
        onUpdateConnectionProfile(owner: $owner) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateConnectionProfile">
      >
    >;
  }

  OnDeleteConnectionProfileListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConnectionProfile">
    >
  > {
    const statement = `subscription OnDeleteConnectionProfile($owner: String) {
        onDeleteConnectionProfile(owner: $owner) {
          __typename
          id
          name
          adapterId
          envPrefix
          accountId
          environment
          userName
          audience
          authKey
          ipUrl
          purpose
          isRegistered
          jwtToken
          userDetail {
            __typename
            id
            ownerEmail
            envPrefix
            name
            username
            hasKeys
            connectionProfiles {
              __typename
              items {
                __typename
                id
                name
                adapterId
                envPrefix
                accountId
                environment
                userName
                audience
                authKey
                ipUrl
                purpose
                isRegistered
                jwtToken
                userDetail {
                  __typename
                  id
                  ownerEmail
                  envPrefix
                  name
                  username
                  hasKeys
                  connectionProfiles {
                    __typename
                    items {
                      __typename
                      id
                      name
                      adapterId
                      envPrefix
                      accountId
                      environment
                      userName
                      audience
                      authKey
                      ipUrl
                      purpose
                      isRegistered
                      jwtToken
                      userDetail {
                        __typename
                        id
                        ownerEmail
                        envPrefix
                        name
                        username
                        hasKeys
                        createdAt
                        updatedAt
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteConnectionProfile">
      >
    >;
  }

  OnCreateAuditLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAuditLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAuditLog {
        onCreateAuditLog {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAuditLog">>
  >;

  OnUpdateAuditLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAuditLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAuditLog {
        onUpdateAuditLog {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAuditLog">>
  >;

  OnDeleteAuditLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAuditLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAuditLog {
        onDeleteAuditLog {
          __typename
          id
          envPrefix
          logTs
          userName
          adapterType
          integration
          action
          message
          payload
          expirationUnixTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAuditLog">>
  >;

  OnCreateIntegrationTypeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIntegrationType">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIntegrationType {
        onCreateIntegrationType {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIntegrationType">
    >
  >;

  OnUpdateIntegrationTypeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIntegrationType">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIntegrationType {
        onUpdateIntegrationType {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIntegrationType">
    >
  >;

  OnDeleteIntegrationTypeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIntegrationType">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIntegrationType {
        onDeleteIntegrationType {
          __typename
          id
          code
          displayName
          logoFilename
          logoHeight
          logoWidth
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIntegrationType">
    >
  >;

  OnCreateEnvironmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEnvironment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEnvironment {
        onCreateEnvironment {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEnvironment">>
  >;

  OnUpdateEnvironmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEnvironment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEnvironment {
        onUpdateEnvironment {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEnvironment">>
  >;

  OnDeleteEnvironmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEnvironment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEnvironment {
        onDeleteEnvironment {
          __typename
          id
          name
          url
          reference
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEnvironment">>
  >;
}