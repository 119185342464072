import {AfterContentInit, AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SchemaService} from '../../../services/schema.service';
import {RawSchemaEntityDialogBodyComponent} from './raw-schema-entity-dialog-body/raw-schema-entity-dialog-body.component';
import {AttributeCustomizationsDialogBodyComponent} from './attribute-customizations-dialog-body/attribute-customizations-dialog-body.component';
import {IntegrationDetails} from '../../../models/integration-details';
import {IntegrationSchema} from '../../../models/integration-schema';
import {IntegrationSchemaEntity} from '../../../models/integration-schema-entity';
import {SchemaEntities} from '../../../models/schema-entities';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import { GlobalUiStateQuery } from '../../../state/global-ui-state.query';
import { NgxSpinnerService } from 'ngx-spinner';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {MatFabMenu} from '@angular-material-extensions/fab-menu';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import { MatTooltipModule} from '@angular/material/tooltip';

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */

@Component({
  selector: 'app-adapter-integration-schemas',
  templateUrl: './adapter-integration-schemas.component.html',
  styleUrls: ['./adapter-integration-schemas.component.scss']
})

export class AdapterIntegrationSchemasComponent implements OnInit, AfterContentInit  {

  @ViewChild('selectedEntitiesTable') selectedEntitiesTable: any;
  @ViewChild('unselectedEntitiesTable') unselectedEntitiesTable: any;

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);
  schema: IntegrationSchema;
  schemaV2: IntegrationSchema;
  schemaForm2: UntypedFormGroup;
  displayedColumns: string[] = ['entityName', 'entityDescription'];
  states: string[] = ['Active', 'Draft', 'Retired'];
  statesV1: string[] = ['published', 'draft'];
  filters: string[] = ['All', 'Active', 'Draft', 'Retired'];

  ColumnMode = ColumnMode;
  selectedEntities: any[] = [];
  filteredSelectedEntities: any[] = [];
  selectedEntitiesFilterValue: string;
  unselectedEntities: any[] = [];
  unselectedEntitiesFilterValue: string;
  filteredUnselectedEntities: any[] = [];
  unselectedEntitiesLoaded: boolean;
  timeout: any;
  entityRemovalList: any[] = [];


  v1Selected = false;
  v1SchemaLoadError = false;
  v1SchemaLoadErrorMessage = "";
  v1DataSource: MatTableDataSource<any>;
  selectedV1Schema : IntegrationSchema;

  v2Selected = true;
  v2SchemaLoadError = false;
  v2SchemaLoadErrorMessage = "";
  v2DataSource: MatTableDataSource<any>;
  schemaListV1 = [];

  isBulkEdit = false;
  changesMade = false;
  bulkEntityList = new SchemaEntities();
  buttons = [];
  fabButtons: MatFabMenu[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private service: SchemaService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public matDialog: MatDialog,
    private globalStateQuery: GlobalUiStateQuery,
    private globalStateService: GlobalUiStateService) {

  }

  ngAfterContentInit () {
    window.dispatchEvent(new Event('resize'))
  }

  async ngOnInit() {

    this.unselectedEntitiesLoaded = false;
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();

    // this.schema = new IntegrationSchema(null);
    this.schemaV2 = new IntegrationSchema(null);

    this.schemaForm2 = this.formBuilder.group({
        stateFilter: ['']
      }
    );

    this.v2DataSource = new MatTableDataSource(this.schemaV2.EntityDefinitions);
    this.v2DataSource.filterPredicate = (data: IntegrationSchemaEntity, filter: string) => {
      if (filter.match('All')) {
        return true;
      }
      return data.State.match(filter) != null;
    };

    this.updateFabButtons();

    await this.spinner.show("selectedEntities");

    this.loadShallowSchemaEntityList(true).then(async schemaData => {
      this.selectedEntities = JSON.parse(schemaData);
      console.log(this.selectedEntities);
      this.filteredSelectedEntities = [...this.selectedEntities];
      this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
      });

      for (const entity of this.selectedEntities) {
        if (this.selectedIntegrationDetails$.metaData) {
          let found = this.selectedIntegrationDetails$.metaData["SyncElements"]?.filter(x => x.Value === entity.Name)
          if (found && found.length > 0) {
            entity.Sync = true;
          }
        }
      }
    });

  }

  onSelectedEntityFilterChange(event)  {
    this.selectedEntitiesFilterValue = event;
    this.filteredSelectedEntities = this.selectedEntities.filter(function (entity) {
      return entity.Name.toUpperCase().indexOf(event.toUpperCase()) > -1;
    });
  }

  onSelectedEntitySelectedAttributeFilterChange(entityName, filterValue)  {
    let entity = this.selectedEntities.filter(entity => entity.Name == entityName)[0];
    entity.selectedEntitySelectedAttributeFilterValue = filterValue;
    entity.FilteredSelectedAttributes = entity.SelectedAttributes.filter(function (attribute) {
      return attribute.Attribute.Name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1;
    });
  }

  onSelectedEntitySelectedAttributeFilterClear(entityName)  {
    let entity = this.selectedEntities.filter(entity => entity.Name == entityName)[0];
    entity.selectedEntitySelectedAttributeFilterValue = "";
    entity.FilteredSelectedAttributes = [...entity.SelectedAttributes];
  }

  onSelectedEntityUnselectedAttributeFilterChange(entityName, filterValue)  {
    let entity = this.selectedEntities.filter(entity => entity.Name == entityName)[0];
    entity.selectedEntityUnselectedAttributeFilterValue = filterValue;
    entity.FilteredUnselectedAttributes = entity.UnselectedAttributes.filter(function (attribute) {
      return attribute.Attribute.Name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1;
    });
  }

  onSelectedEntityUnselectedAttributeFilterClear(entityName)  {
    let entity = this.selectedEntities.filter(entity => entity.Name == entityName)[0];
    entity.selectedEntityUnselectedAttributeFilterValue = "";
    entity.FilteredUnselectedAttributes = [...entity.UnselectedAttributes];
  }

  onSelectedEntityFilterClear()  {
    this.selectedEntitiesFilterValue = "";
    this.filteredSelectedEntities = [...this.selectedEntities];
  }

  onUnselectedEntityFilterChange(event)  {
    this.unselectedEntitiesFilterValue = event;
    this.filteredUnselectedEntities = this.unselectedEntities.filter(function (entity) {
      return entity.Name.toUpperCase().indexOf(event.toUpperCase()) > -1;
    });
  }

  onUnselectedEntityFilterClear()  {
    this.unselectedEntitiesFilterValue = "";
    this.filteredUnselectedEntities = [...this.unselectedEntities];
  }

  async onUnselectedEntityDetailToggle(event) {

    let entity = this.filteredUnselectedEntities.filter(entity => entity.Name == event.value.Name)[0];

    await this.spinner.show("unselectedAttributes_" + entity.Name);

    if (entity.SelectedAttributesLoaded === false) {
      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, true).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.SelectedAttributes = JSON.parse(data);
      entity.FilteredSelectedAttributes = JSON.parse(data);
      entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.FilteredSelectedAttributes = entity.FilteredSelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.UnselectedAttributes = [];
      entity.SelectedAttributesLoaded = true;
    }

    await this.spinner.hide("unselectedAttributes_" + entity.Name);

  }

  async onSelectedEntityDetailToggle(event) {

    let entity = this.selectedEntities.filter(entity => entity.Name == event.value.Name)[0];

    await this.spinner.show("selectedAttributes_" + entity.Name);

    if (!entity.SelectedAttributesLoaded || entity.SelectedAttributesLoaded === false) {
      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, true).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.SelectedAttributes = JSON.parse(data);
      entity.FilteredSelectedAttributes = JSON.parse(data);
      entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.FilteredSelectedAttributes = entity.FilteredSelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.UnselectedAttributes = [];
      entity.SelectedAttributesLoaded = true;
    }

    await this.spinner.hide("selectedAttributes_" + entity.Name);
  }

  async toggleUnselectedAttributes(entityName) {

    let entity = this.selectedEntities.filter(entity => entity.Name == entityName)[0];

    await this.spinner.show("unselectedAttributes_" + entity.Name);

    const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, false).toPromise();
    let data = JSON.stringify(result.body, undefined, 4);
    entity.UnselectedAttributes = JSON.parse(data);
    entity.FilteredUnselectedAttributes = JSON.parse(data);
    entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.UnselectedAttributesLoaded = true;

    await this.spinner.hide("unselectedAttributes_" + entity.Name);
  }

  toggleExpandSelectedEntitiesRow(row) {
    this.selectedEntitiesTable.rowDetail.toggleExpandRow(row);
  }

  toggleExpandUnselectedEntitiesRow(row) {
    this.unselectedEntitiesTable.rowDetail.toggleExpandRow(row);
  }

  async fetchUnselectedEntities() {
    await this.spinner.show("deselectedEntities");
    await this.loadShallowSchemaEntityList(false).then(schemaData => {
      this.unselectedEntities = JSON.parse(schemaData);
      this.unselectedEntities = this.unselectedEntities.slice().sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
      });
      this.filteredUnselectedEntities = [...this.unselectedEntities];
      this.unselectedEntities.forEach(entity => {
        entity.SelectedAttributesLoaded = false;
      })
      this.unselectedEntitiesLoaded = true;
    });
    await this.spinner.hide("deselectedEntities");
  }

  async removeSelectedAttribute(entityName, attributeName) {

    let entity = this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === entityName)];

    if (!entity.UnselectedAttributesLoaded || entity.UnselectedAttributesLoaded === false) {
      await this.spinner.show("processing");
      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, false).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.UnselectedAttributes = JSON.parse(data);
      entity.FilteredUnselectedAttributes = [...entity.UnselectedAttributes];
      entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.UnselectedAttributesLoaded = true;
      this.unselectedEntitiesLoaded = true;
      await this.spinner.hide("processing");
    }

    let entityAttribute = entity.SelectedAttributes[entity.SelectedAttributes.findIndex(v => v.Attribute.Name === attributeName)];
    entity.SelectedAttributes = entity.SelectedAttributes.filter(attribute => attribute.Attribute.Name != attributeName);
    entity.FilteredSelectedAttributes = entity.SelectedAttributes.filter(attribute => attribute.Attribute.Name != attributeName);
    entity.UnselectedAttributes.push(entityAttribute);
    entity.FilteredUnselectedAttributes.push(entityAttribute);
    entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.isDirty = true;
    this.changesMade = true;
    this.updateFabButtons();
  }

  async removeAllSelectedAttributes(entityName) {

    await this.spinner.show("processing");

    let entity = this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === entityName)];

    if (!entity.UnselectedAttributesLoaded) {

      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, false).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.UnselectedAttributes = JSON.parse(data);
      entity.FilteredUnselectedAttributes = JSON.parse(data);
      entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.UnselectedAttributesLoaded = true;
    }

    Array.prototype.push.apply(entity.UnselectedAttributes, entity.SelectedAttributes);
    entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.FilteredUnselectedAttributes = [...entity.UnselectedAttributes];
    entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });

    entity.SelectedAttributes = [];
    entity.FilteredSelectedAttributes = [];

    entity.isDirty = true;
    this.changesMade = true;
    this.updateFabButtons();
    await this.spinner.hide("processing");

  }

  async addAllUnselectedAttributes(entityName) {

    await this.spinner.show("processing");

    let entity = this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === entityName)];

    if (!entity.UnselectedAttributesLoaded) {
      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, false).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.UnselectedAttributes = JSON.parse(data);
      entity.FilteredUnselectedAttributes = JSON.parse(data);
      entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.UnselectedAttributesLoaded = true;
    }

    Array.prototype.push.apply(entity.SelectedAttributes, entity.UnselectedAttributes);
    entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.FilteredSelectedAttributes = [...entity.SelectedAttributes];
    entity.FilteredSelectedAttributes = entity.FilteredSelectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });

    entity.UnselectedAttributes = [];
    entity.FilteredUnselectedAttributes = [];

    entity.isDirty = true;
    this.changesMade = true;
    this.updateFabButtons();

    await this.spinner.hide("processing");
  }

  addUnselectedAttribute(entityName, attributeName) {
    let entity = this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === entityName)];
    let entityAttribute = entity.UnselectedAttributes[entity.UnselectedAttributes.findIndex(v => v.Attribute.Name === attributeName)];
    entity.UnselectedAttributes = entity.UnselectedAttributes.filter(attribute => attribute.Attribute.Name != attributeName);
    entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.filter(attribute => attribute.Attribute.Name != attributeName);
    entity.SelectedAttributes.push(entityAttribute);
    entity.FilteredSelectedAttributes.push(entityAttribute);
    entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.FilteredSelectedAttributes = entity.FilteredSelectedAttributes.slice().sort((a, b) => {
      return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
    });
    entity.isDirty = true;
    this.changesMade = true;
    this.updateFabButtons();
  }

  async removeSelectedEntity(name) {

    let entity = this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === name)];

    // need to add this entity to a global removal list and a call will need to be made on save to remove
    // all entities in the removal list
    this.entityRemovalList.push(entity);

    if (this.unselectedEntitiesLoaded === false) {
      await this.spinner.show("processing");
      await this.loadShallowSchemaEntityList(false).then(async schemaData => {
        this.unselectedEntities = JSON.parse(schemaData);
        this.filteredUnselectedEntities = JSON.parse(schemaData);
        if (!entity.SelectedAttributesLoaded || entity.SelectedAttributesLoaded === false) {
          const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, true).toPromise();
          let data = JSON.stringify(result.body, undefined, 4);
          entity.SelectedAttributes = JSON.parse(data);
          entity.FilteredSelectedAttributes = JSON.parse(data);
          entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
            return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
          });
          entity.FilteredSelectedAttributes = entity.FilteredSelectedAttributes.slice().sort((a, b) => {
            return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
          });
          entity.UnselectedAttributes = [];
          entity.SelectedAttributesLoaded = true;
        }
        if (entity.UnselectedAttributes == null || (entity.UnselectedAttributes != null && entity.UnselectedAttributes.length == 0)) {
          const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, false).toPromise();
          let data = JSON.stringify(result.body, undefined, 4);
          entity.UnselectedAttributes = JSON.parse(data);
          entity.UnselectedAttributes = entity.UnselectedAttributes.slice().sort((a, b) => {
            return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
          });
          entity.FilteredUnselectedAttributes = [...entity.UnselectedAttributes];
          entity.FilteredUnselectedAttributes = entity.FilteredUnselectedAttributes.slice().sort((a, b) => {
            return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
          });
          entity.UnselectedAttributesLoaded = true;
        }

        if (entity.UnselectedAttributes != null)  {
          Array.prototype.push.apply(entity.SelectedAttributes, entity.UnselectedAttributes);
          Array.prototype.push.apply(entity.FilteredSelectedAttributes, entity.FilteredUnselectedAttributes);
        }

        entity.UnselectedAttributes = [];
        entity.FilteredUnselectedAttributes = [];
        entity.isDirty = true;

        this.selectedEntities = this.selectedEntities.filter(entity => entity.Name != name);
        this.selectedEntities = this.selectedEntities.slice().sort((a, b) => {
          return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
        });
        this.filteredSelectedEntities = [...this.selectedEntities];
        this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
          return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
        });
        this.unselectedEntities.push(entity);
        this.filteredUnselectedEntities = [...this.unselectedEntities];
        this.unselectedEntities = this.unselectedEntities.slice().sort((a, b) => {
          return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
        });
        this.filteredUnselectedEntities = this.filteredUnselectedEntities.slice().sort((a, b) => {
          return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
        });
        this.changesMade = true;
        this.unselectedEntitiesLoaded = true;
        this.updateFabButtons();
        await this.spinner.hide("processing");
      });
    }
    else {

      if (entity.UnselectedAttributes != null) {
        Array.prototype.push.apply(entity.SelectedAttributes, entity.UnselectedAttributes);
        Array.prototype.push.apply(entity.FilteredSelectedAttributes, entity.FilteredUnselectedAttributes);
      }

      entity.UnselectedAttributes = [];
      entity.FilteredUnselectedAttributes = [];
      entity.isDirty = true;

      this.selectedEntities = this.selectedEntities.filter(entity => entity.Name != name);
      this.filteredSelectedEntities = [...this.selectedEntities];
      this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
      });
      this.unselectedEntities.push(entity);
      this.filteredUnselectedEntities = [...this.unselectedEntities];
      this.unselectedEntities = this.unselectedEntities.slice().sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
      });
      this.filteredUnselectedEntities = this.filteredUnselectedEntities.slice().sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
      });
      this.changesMade = true;
      this.unselectedEntitiesLoaded = true;
      this.updateFabButtons();
      await this.spinner.hide("processing");
    }
  }

  async addUnselectedEntity(name) {

    let entity = this.unselectedEntities[this.unselectedEntities.findIndex(v => v.Name === name)];

    // if this entity had been removed and no save had been called, we need to take it out of the removal list
    this.entityRemovalList = this.entityRemovalList.filter(entity => entity.Name != name);

    this.unselectedEntities = this.unselectedEntities.filter(entity => entity.Name != name);
    this.filteredUnselectedEntities = [...this.unselectedEntities];
    this.filteredUnselectedEntities = this.filteredUnselectedEntities.slice().sort((a, b) => {
      return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
    });
    this.selectedEntities.push(entity);
    this.filteredSelectedEntities = [...this.selectedEntities];
    this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
      return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
    });

    if (!entity.SelectedAttributesLoaded || entity.SelectedAttributesLoaded === false) {
      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, true).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.SelectedAttributes = JSON.parse(data);
      entity.FilteredSelectedAttributes = JSON.parse(data);
      entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.FilteredSelectedAttributes = entity.FilteredSelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.UnselectedAttributes = [];
      entity.SelectedAttributesLoaded = true;
    }

    entity.isDirty = true;
    this.changesMade = true;
    this.updateFabButtons();
  }

  async processFabButtonClick(buttonId) {
    if (buttonId == 1) { // clear schema cache
      await this.clearSchemaCache();
    } else if (buttonId == 2) { // move to edit mode
      this.deleteSchema('Delete');
    } else if (buttonId == 3) { // activate selected
      this.activateSchema();
    } else if (buttonId == 4) { // retire selected
      this.retireSchema();
    } else if (buttonId == 5) { // update schema filter
      await this.updateSchemaFilter();
    } else if (buttonId == 6) { // reset to default schema filter
      await this.applyDefaultSchemaFilter();
    } else if (buttonId == 7) { //delete schema filter
      await this.deleteSchemaFilter();
    } else if (buttonId == 8) { // download schema
      await this.downloadFile();
    }

  }

  updateFabButtons()  {

    const updatedFabButtons = [];

    if (this.changesMade)
    {
      updatedFabButtons.push({
        id: 5,
        icon: 'save',
        tooltip: 'Save Changes',
        tooltipPosition: 'left'
      });
    }

    updatedFabButtons.push({
      id: 1,
      icon: 'cached',
      tooltip: 'Clear Schema Cache',
      tooltipPosition: 'left'
    });

    updatedFabButtons.push({
      id: 6,
      icon: 'blur_on',
      tooltip: 'Reset To Default Filter',
      tooltipPosition: 'left'
    });

    updatedFabButtons.push({
      id: 8,
      icon: 'download',
      tooltip: 'Download Full Schema',
      tooltipPosition: 'left'
    });

    // updatedFabButtons.push({
    //   id: 2,
    //   icon: 'delete',
    //   tooltip: 'Delete Schema',
    //   tooltipPosition: 'left'
    // });

    if (this.isBulkEdit && this.v2Selected) {
      updatedFabButtons.push({
        id: 3,
        icon: 'brightness_high',
        tooltip: 'Activate Selected',
        tooltipPosition: 'left'
      });
      updatedFabButtons.push({
        id: 4,
        icon: 'remove_circle',
        tooltip: 'Retire Selected',
        tooltipPosition: 'left'
      });
    }

    this.fabButtons = JSON.parse(JSON.stringify(updatedFabButtons));
    this.buttons = JSON.parse(JSON.stringify(updatedFabButtons));
  }



  loadSchemaV2(){

    this.service.getIntegrationSchema(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        this.schemaV2 = new IntegrationSchema(result.body);
        this.v2DataSource.data = this.schemaV2.EntityDefinitions;
        /*if ( this.v1SchemaLoaded === false ) {
          this.selectedTab = 1;
        }*/
        this.v2DataSource.filter = 'Active';
      },
      error => {

        if( error.name === "TimeoutError")
        {
          this.showToaster('List schema call failed. Call Timed out!', 1);
        }else{
          this.v2SchemaLoadError = true;
          const errorDetail = error.error.errors[0];
          this.v2SchemaLoadErrorMessage = errorDetail.Status + " : " + errorDetail.Title + " : " + errorDetail.Detail;
        }
        //this.spinner.hide();
      },
      () => {
        //this.spinner.hide();
      }
    );
  }

  showToaster(message, statusCode){
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  async openAttributesOfSelectedEntity(entityName) {

    await this.spinner.show("processing");

    let response = await this.service.getSingleSchemaEntity(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entityName).toPromise();

    await this.spinner.hide("processing");

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = response.body["RawSchema"]["EntityDefinitions"][0];
    const viewDialog = this.matDialog.open(RawSchemaEntityDialogBodyComponent, dialogConfig);
    viewDialog.afterClosed().subscribe(async data => {
      if (data === undefined) {
        return;
      }
      if (data == true)
        await this.clearSchemaCache();
    });
  }

  async openAttributesOfUnselectedEntity(entityName) {

    let entity = this.unselectedEntities[this.unselectedEntities.findIndex(v => v.Name === entityName)];

    if (entity.SelectedAttributesLoaded === false) {
      await this.spinner.show("processing");
      const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, true).toPromise();
      let data = JSON.stringify(result.body, undefined, 4);
      entity.SelectedAttributes = JSON.parse(data);
      entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
        return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
      });
      entity.SelectedAttributesLoaded = true;
      await this.spinner.hide("processing");
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = entity;
    const viewDialog = this.matDialog.open(RawSchemaEntityDialogBodyComponent, dialogConfig);
    viewDialog.afterClosed().subscribe(async data => {
      if (data === undefined) {
        return;
      }
      if( data )
        await this.clearSchemaCache();
    });
  }

  async openAttributeCustomizationsDialog(entityName, attributeName) {

    let entity = this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === entityName)];
    let entityAttribute = entity.SelectedAttributes[entity.SelectedAttributes.findIndex(v => v.Attribute.Name === attributeName)];

    const initialDefaultValue = entityAttribute.EntityAttributeCustomizations != null ? entityAttribute.EntityAttributeCustomizations["DefaultOverride"] : null;

    console.log("InitialDefaultValue: " + initialDefaultValue);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = entityAttribute;
    const viewDialog = this.matDialog.open(AttributeCustomizationsDialogBodyComponent, dialogConfig);
    viewDialog.afterClosed().subscribe(async data => {
      if (data === undefined) {
        return;
      }
      if( data ) {
        var customizedDefaultValue = data.EntityAttributeCustomizations != null ? data.EntityAttributeCustomizations["DefaultOverride"] : null;
        console.log("customizedDefaultValue: " + customizedDefaultValue);
        if (initialDefaultValue != customizedDefaultValue) {
          entity.isDirty = true;
        }
        this.changesMade = true;
        this.updateFabButtons();
        entity.FilteredSelectedAttributes = [...entity.SelectedAttributes];
      }
    });
  }

  applyFilter(filter: string){
    this.v2DataSource.filter = filter;
  }


  deleteSchema(action) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Schema',
        message: 'Are you sure, you want to remove the Schema for this integration?',
        buttonYes: 'Confirm',
        buttonNo: 'Cancel'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.deleteSchemaEntity(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
          () => {
            this.showToaster('Schema Delete', 0);
            this.v2DataSource.data = null;
          },
          error => {
            if( error.name === "TimeoutError")
            {
              this.showToaster('Call failed to delete Schema. Call Timed out!', 1);
            }else{
              this.showToaster('Call failed to delete Schema. Status code: ' + error.status, 1);
            }
          },
          () => {
          }
        );
      }
    });

  }

  activateSchema(){
    if (this.bulkEntityList.schemaEntities.length === 0 ) {
      return;
    }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Retire Entities',
        message: 'Would you like to retire previous schema entities?.',
        buttonYes: 'Yes',
        buttonNo: 'No'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      let retire = false;
      if (result === true) {
        retire = true;
      }
      this.spinner.show();
      this.service.activateSchemaEntities(
        this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, retire, this.bulkEntityList)
        .subscribe(
          () => {
            this.showToaster('Schema Entities activated', 0);
            this.bulkEntityList = new SchemaEntities();
            this.loadSchemaV2();
          },
          error => {
            if( error.name === "TimeoutError")
            {
              this.showToaster('Call failed to activate Schema. Call Timed out!', 1);
            }else{
              this.showToaster('Call failed to activate Schema. Status code: ' + error.status, 1);
            }
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
          }
        );
    });

  }

  retireSchema(){
    if (this.bulkEntityList.schemaEntities.length === 0 ) {
      return;
    }
    this.spinner.show();
    this.service.retireSchemaEntities(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId, this.bulkEntityList).subscribe(
      result => {
        this.showToaster('Schema Entities Retired', 0);
        this.bulkEntityList = new SchemaEntities();
        this.loadSchemaV2();
      },
      error => {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Call failed to retire Schema entities. Call Timed out!', 1);
        }else{
          this.showToaster('Call failed to retire Schema entities. Status code: ' + error.status, 1);
        }
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  async updateSchemaFilter() {

    await this.spinner.show("processing");

    let dirtyEntities = [];

    for (const filteredEntity of this.selectedEntities) {
      if (filteredEntity.isDirty) {
        if (!filteredEntity.SelectedAttributesLoaded) {
          const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, filteredEntity.Name, true).toPromise();
          let data = JSON.stringify(result.body, undefined, 4);
          filteredEntity.SelectedAttributes = JSON.parse(data);
          filteredEntity.SelectedAttributesLoaded = true;
        }
        dirtyEntities.push(filteredEntity);
      }
    }

    let syncs: string[] = [];
    this.selectedEntities.forEach( x =>
      {
        if( x.Sync === true )
        {
          syncs.push(x.Name);
        }
      }
    )

    if (dirtyEntities.length == 0 && syncs.length == 0 && this.entityRemovalList.length == 0)
    {
      this.showToaster('Nothing to update ...', 0);
      return;
    }

    console.log(dirtyEntities);

    if (this.entityRemovalList.length > 0) {
      try {

        await this.service.removeEntitiesFromSchemaFilter(this.selectedAdapterDetails$['endpoint'],
          this.selectedIntegrationDetails$.integrationId, this.entityRemovalList).toPromise();

        this.showToaster('Schema Filter Updated - Entity(s) removed ...', 0);
        this.entityRemovalList = [];

      } catch (error) {
        if (error.name === "TimeoutError") {
          this.showToaster('Call failed to remove Schema filter entities. Call Timed out!', 1);
        } else {
          this.showToaster('Call failed to remove Schema filter entities. Status code: ' + error.status, 1);
        }
        console.log(error);
      } finally {
        if (dirtyEntities.length == 0 && syncs.length == 0) {
          await this.spinner.hide("processing");
        }
      }
    }

    if (dirtyEntities.length > 0) {
      try {
        await this.service.updateSchemaFilter(this.selectedAdapterDetails$['endpoint'],
          this.selectedIntegrationDetails$.integrationId, dirtyEntities).toPromise();
          this.showToaster('Schema Filter Updated', 0);
      } catch(error) {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Call failed to update Schema filter. Call Timed out!', 1);
        }else{
          this.showToaster('Call failed to update Schema filter. Status code: ' + error.status, 1);
        }
        console.log(error);
      } finally {
        if (syncs.length == 0)  {
          await this.spinner.hide("processing");
        }
      }
    }

    if (syncs.length > 0) {
      try {
        await this.service.updateEntitySync(this.selectedAdapterDetails$['endpoint'],
          this.selectedIntegrationDetails$.integrationId,syncs).toPromise();
        this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);
        this.showToaster('Schema Syncs Updated', 0);
      } catch(error) {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Call failed to update Schema syncs. Call Timed out!', 1);
        }else{
          this.showToaster('Call failed to update Schema syncs. Status code: ' + error.status, 1);
        }
        console.log(error);
      } finally {
          await this.spinner.hide("processing");
      }
    }
  }

  async applyDefaultSchemaFilter() {

    await this.spinner.show("processing");

    this.service.applyDefaultSchemaFilter(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).subscribe(
      async result => {
        await this.spinner.show("processing");
        this.unselectedEntities = [];
        this.unselectedEntitiesLoaded = false;
        this.selectedEntities = [];
        await this.loadShallowSchemaEntityList(true).then(async schemaData => {
          this.selectedEntities = JSON.parse(schemaData);
          this.selectedEntities = this.selectedEntities.slice().sort((a, b) => {
            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
          });
          this.filteredSelectedEntities = [...this.selectedEntities];
          this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
          });
          if (this.selectedEntities.length < 30)  {
            for (const filteredEntity of this.filteredSelectedEntities) {
              if (this.selectedIntegrationDetails$.metaData)  {
                let found = this.selectedIntegrationDetails$.metaData["SyncElements"]?.filter(x=> x.Value === filteredEntity.Name)
                if ( found && found.length > 0 )
                {
                  filteredEntity.Sync = true;
                }
              }
              await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, filteredEntity.Name, true).subscribe(
                result => {
                  let data = JSON.stringify(result.body, undefined, 4);
                  filteredEntity.SelectedAttributes = JSON.parse(data);
                  filteredEntity.SelectedAttributes = filteredEntity.SelectedAttributes.slice().sort((a, b) => {
                    return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
                  });
                  filteredEntity.FilteredSelectedAttributes = [...filteredEntity.SelectedAttributes];
                  filteredEntity.SelectedAttributesLoaded = true;
                  filteredEntity.UnselectedAttributesLoaded = false;
                },
                error => {
                },
                async () => {

                }
              );
            }
          }

          this.selectedEntitiesFilterValue = "";

          let syncs: string[] = [];
          this.selectedEntities.forEach( x =>
            {
              if( x.Sync === true )
              {
                syncs.push(x.Name);
              }
            }
          )

          this.service.updateEntitySync(this.selectedAdapterDetails$['endpoint'],
            this.selectedIntegrationDetails$.integrationId,syncs).subscribe(
            result => {
              this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);
              this.showToaster('Schema Syncs Updated', 0);
            },
            async error => {
              if( error.name === "TimeoutError")
              {
                this.showToaster('Call failed to update Schema syncs. Call Timed out!', 1);
              }else{
                this.showToaster('Call failed to update Schema syncs. Status code: ' + error.status, 1);
              }
            },
            async () => {
              await this.spinner.hide("processing");
            }
          )

        });
      },
      async error => {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Call failed to apply default Schema filter. Call Timed out!', 1);
        }else{
          this.showToaster('Call failed to apply default Schema filter. Status code: ' + error.status, 1);
        }
        await this.spinner.hide("processing");

      },
      async () => {

      }
    );
  }

  downloadFile()  {
    this.service.getIntegrationSchema(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).subscribe(
      (response: any) =>{
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(JSON.stringify(response.body["RawSchema"], undefined, 2));
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', `integration_${this.selectedIntegrationDetails$.integrationId}_schema.json`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }

  async deleteSchemaFilter() {

    await this.spinner.show("processing");

    this.service.deleteSchemaFilter(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).subscribe(
      async result => {
        await this.spinner.show("processing");
        this.unselectedEntities = [];
        this.unselectedEntitiesLoaded = false;
        this.selectedEntities = [];
        await this.loadShallowSchemaEntityList(true).then(async schemaData => {
          this.selectedEntities = JSON.parse(schemaData);
          this.selectedEntities = this.selectedEntities.slice().sort((a, b) => {
            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
          });
          this.filteredSelectedEntities = [...this.selectedEntities];
          this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
          });
          if (this.selectedEntities.length < 30)  {
            for (const entity of this.selectedEntities) {
              if (this.selectedIntegrationDetails$.metaData)  {
                let found = this.selectedIntegrationDetails$.metaData["SyncElements"]?.filter(x=> x.Value === entity.Name)
                if ( found && found.length > 0 )
                {
                  entity.Sync = true;
                }
              }
              const result = await this.service.getSchemaEntityAttributesAndCustomizationsAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, entity.Name, true).subscribe(
                result => {
                  let data = JSON.stringify(result.body, undefined, 4);
                  entity.SelectedAttributes = JSON.parse(data);
                  entity.SelectedAttributes = entity.SelectedAttributes.slice().sort((a, b) => {
                    return a.Attribute.Name.toUpperCase().localeCompare(b.Attribute.Name.toUpperCase())
                  });
                  entity.SelectedAttributesLoaded = true;
                  entity.UnselectedAttributesLoaded = false;
                },
                error => {
                },
                async () => {
                }
              );
            }
          }
          await this.spinner.hide("processing");
        });
      },
      async error => {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Call failed to apply default Schema filter. Call Timed out!', 1);
        }else{
          this.showToaster('Call failed to apply default Schema filter. Status code: ' + error.status, 1);
        }
        await this.spinner.hide("processing");
      },
      async () => {

      }
    );
  }

  loadSchemaV1List(){

    this.service.listIntegrationSchemaV1(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        this.schemaListV1 = [];
        var schemaList = JSON.parse(JSON.stringify(result.body, undefined, 4))
        for (let i = 0; i < schemaList.length; i++) {
          this.schemaListV1.push(new IntegrationSchema(schemaList[i]));
        }
        this.v1DataSource = new MatTableDataSource(this.schemaListV1);

      },
      error => {

        this.v1SchemaLoadError = true;
        const errorDetail = error.error.errors[0];
        this.v1SchemaLoadErrorMessage = errorDetail.Status + " : " + errorDetail.Title + " : " + errorDetail.Detail;

        this.spinner.hide();
      },
      () => {
        this.spinner.hide(); }
    );
  }

  async loadShallowSchemaEntityList(selected): Promise<string> {

    let data = '{"entities": []}';
    try {
      const result = await this.service.getShallowSchemaEntitiesAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, selected).toPromise();
      data = JSON.stringify(result.body, undefined, 4);
    } catch(ex) {

    } finally {
      if(selected)  {
        await this.spinner.hide("selectedEntities");
      } else {
        await this.spinner.hide("deselectedEntities");
      }

    }

    return data;
  }

  openDialog(action, obj, event) {
    this.selectedV1Schema = obj;
    obj.action = action;

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: obj,
        title: 'Schema',
        message: 'Are you sure you want to Schema  '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.service.deleteIntegrationSchemaV1(this.selectedAdapterDetails$['endpoint'],
          result.data.dataObj).subscribe(
          () => {
            this.v1DataSource.data =  this.v1DataSource.data.filter((value,key)=>{
              return value.SchemaVersion != this.selectedV1Schema.SchemaVersion;
            });
            this.showToaster('Schema Job  deleted.', 0);

          },
          error => {
            if( error.name === "TimeoutError")
            {
              this.showToaster('Delete call failed. Call Timed out!', 1);
            }else{
              this.showToaster('Delete call failed. Status code: ' + error.status, 1);
            }

          },
          () => {
          }
        );
      }
    });
  }

  async clearSchemaCache() {
    await this.spinner.show("processing");
    this.service.clearSchemaCache(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      async result => {
        this.showToaster("Clear Schema Cache call succeeded", 0);
        this.selectedEntities = [];
        this.unselectedEntities = [];
        await this.loadShallowSchemaEntityList(true).then(schemaData => {
          this.selectedEntities = JSON.parse(schemaData);
          this.selectedEntities = this.selectedEntities.slice().sort((a, b) => {
            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
          });
          this.filteredSelectedEntities = [...this.selectedEntities];
          this.filteredSelectedEntities = this.filteredSelectedEntities.slice().sort((a, b) => {
            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())
          });
          for (const entity of this.selectedEntities) {
            if (this.selectedIntegrationDetails$.metaData)  {
              let found = this.selectedIntegrationDetails$.metaData["SyncElements"]?.filter(x=> x.Value === entity.Name)
              if ( found && found.length > 0 )
              {
                entity.Sync = true;
              }
            }
          }
        });
        this.unselectedEntitiesLoaded = false;

        this.selectedEntitiesFilterValue = "";

        await this.spinner.hide("processing");
      },
      async error => {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Clear Schema Cache call failed. Call Timed out!', 1);
        }else{
          this.showToaster("Clear Schema Cache call failed. Status code: " + error.status, 1);
        }
        await this.spinner.hide("processing");
      },
      async () => {

      }
    );
  }

  syncChanged(event: any, row: any) {
    let metaElements;
    this.selectedEntities[this.selectedEntities.findIndex(v => v.Name === row['Name'])].Sync = event.checked;

    if (event.checked === false) {
      this.selectedIntegrationDetails$ = {...this.selectedIntegrationDetails$};
      metaElements = this.selectedIntegrationDetails$.metaData['SyncElements']?.filter(x => x.Value !== row['Name']);
      let meta = JSON.parse(JSON.stringify(this.selectedIntegrationDetails$.metaData));
      meta['SyncElements'] = metaElements;
      this.selectedIntegrationDetails$.metaData = JSON.parse(JSON.stringify(meta));
    } else {

      this.selectedIntegrationDetails$ = {...this.selectedIntegrationDetails$};
      if (this.selectedIntegrationDetails$.metaData)  {
        metaElements = JSON.parse(JSON.stringify(this.selectedIntegrationDetails$.metaData));
        if (!metaElements['SyncElements'])  {
          metaElements['SyncElements'] = [];
        }
      } else {
        metaElements = {};
        metaElements['SyncElements'] = [];
      }

      metaElements['SyncElements'].push({Value: row['Name']});
      this.selectedIntegrationDetails$.metaData = JSON.parse(JSON.stringify(metaElements));
    }

    this.changesMade = true;
    this.updateFabButtons();
  }
}
