import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './nav/nav.component';
import { AdaptersComponent } from './adapters/adapter-list/adapters.component';
import { FooterComponent } from './footer/footer.component';
import { MatCardModule} from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';

import {FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdapterDetailsComponent } from './adapters/adapter-details/adapter-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntegrationsComponent } from './integrations/integrations/integrations.component';
import { selectedIntegrationDetails$Component } from './integrations/integration-details/integration-details/integration-details.component';
import { AdminUiUsersComponent } from './security/admin-ui-users/admin-ui-users.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AdapterDetailsNavComponent } from './adapters/adapter-details/adapter-details-nav/adapter-details-nav.component';
import { AdapterDetailsHeaderComponent } from './adapters/adapter-details/adapter-details-header/adapter-details-header.component';
import { AdapterDetailsHealthComponent } from './adapters/adapter-details/adapter-details-health/adapter-details-health.component';
import { LoglevelDialogComponent } from './adapters/adapter-details/adapter-details-health/loglevel-dialog/loglevel-dialog.component';
import { AdapterDetailsIntegrationsComponent } from './adapters/adapter-details/adapter-details-integrations/adapter-details-integrations.component';
import { AdapterselectedIntegrationDetails$Component } from './adapters/adapter-integration/adapter-integration-details/adapter-integration-details.component';
import { AdapterIntegrationComponent } from './adapters/adapter-integration/adapter-integration.component';
import { AdapterIntegrationNavComponent } from './adapters/adapter-integration/adapter-integration-nav/adapter-integration-nav.component';
import { AdapterIntegrationHeaderComponent } from './adapters/adapter-integration/adapter-integration-header/adapter-integration-header.component';
import { AdapterIntegrationSchemasComponent } from './adapters/adapter-integration/adapter-integration-schemas/adapter-integration-schemas.component';
import { AdapterIntegrationStatsComponent } from './adapters/adapter-integration/adapter-integration-stats/adapter-integration-stats.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HealthCheckService } from './services/health-check.service';
import { AdapterService } from './services/adapter.service';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { AlertDialogBoxComponent } from './alert-dialog-box/alert-dialog-box.component';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { AdapterIntegrationUsersComponent } from './adapters/adapter-integration/adapter-integration-users/adapter-integration-users.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ScheduledJobAdminService } from './services/scheduled-job-admin.service';
import { ConfirmDialogComponent } from './adapters/adapter-integration/adapter-integration-schemas/confirm-dialog/confirm-dialog.component';
import { RawSchemaEntityDialogBodyComponent } from './adapters/adapter-integration/adapter-integration-schemas/raw-schema-entity-dialog-body/raw-schema-entity-dialog-body.component';
import { AttributeCustomizationsDialogBodyComponent } from './adapters/adapter-integration/adapter-integration-schemas/attribute-customizations-dialog-body/attribute-customizations-dialog-body.component';
import { SchemaService } from './services/schema.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaterialModule } from './material/material.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { JWTInfoDialogComponent } from './adapters/adapter-list/jwtinfo-dialog/jwtinfo-dialog.component';
import { ConnectionTestDialogComponent } from './adapters/adapter-integration/adapter-integration-header/connectiontest-dialog/connection-test-dialog.component'

import {Amplify, Auth} from 'aws-amplify';
import awsconfig from '../aws-exports';
import { AddEditAdminUiUserComponent } from './security/admin-ui-users/add-edit-admin-ui-user/add-edit-admin-ui-user.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { AdapterCardComponent } from './adapters/adapter-list/adapter-card/adapter-card.component';
import { IntegrationCardComponent } from './adapters/adapter-details/adapter-details-integrations/integration-card/integration-card.component';
import { AdapterMaintenanceComponent } from './adapters/adapter-maintenance/adapter-maintenance.component';
import { AdapterIntegrationMappingsComponent } from './adapters/adapter-integration/adapter-integration-mappings/adapter-integration-mappings.component';
import { AuditLogsComponent } from './security/audit/audit-logs/audit-logs.component';
import { SecurityComponent } from './security/security.component';
import { SecurityNavComponent } from './security/nav/nav.component';
import { AdapterIntegrationQuerytoolComponent } from './adapters/adapter-integration/adapter-integration-querytool/adapter-integration-querytool.component';
import { PagerComponent } from './components/pager/pager.component';
import { AuditlogdialogComponent } from './components/auditlogdialog/auditlogdialog.component';
import {MomentModule} from 'ngx-moment';
import { QueryBuilderDialogComponent } from './adapters/adapter-integration/adapter-integration-querytool/query-builder-dialog/query-builder-dialog.component';
import { QueryBuilderConditionDialogComponent } from './adapters/adapter-integration/adapter-integration-querytool/query-builder-dialog/query-builder-condition-dialog/query-builder-condition-dialog.component';
import { MatFabMenuModule } from '@angular-material-extensions/fab-menu';
import {AdapterDetailsConfigComponent} from './adapters/adapter-details/adapter-details-config/adapter-details-config.component';
import {MatTreeModule} from '@angular/material/tree';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { ConfigIntegrationTypesComponent } from './config/config-integration-types/config-integration-types.component';
import { ConfigFiltersComponent } from './config/config-filters/config-filters.component';
import { ConfigEnvironmentsComponent } from './config/config-environments/config-environments.component';
import { ConfigComponent } from './config/config.component';
import {ConfigNavComponent } from './config/nav/nav.component';
import {AddEditIntTypeComponent} from './config/config-integration-types/add-edit-int-type/add-edit-int-type.component';
import {AddEditFilterComponent} from './config/config-filters/add-edit-filter/add-edit-filter.component';
import {AddEditEnvironmentComponent} from './config/config-environments/add-edit-environment/add-edit-environment.component';
import { AdapterAuditLogComponent } from './adapters/adapter-details/adapter-audit-log/adapter-audit-log.component';
import { AdapterIntegrationAuditLogsComponent } from './adapters/adapter-integration/adapter-integration-audit-logs/adapter-integration-audit-logs.component';
import {AuditTracker} from './services/AuditTracker';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from './interceptors/TimeoutInterceptor';
import {ErrorPageComponent} from './shared/error-page/error-page.component';
import {LoggingService} from './services/logging.service';
import { AdapterDetailsLogsComponent } from './adapters/adapter-details/adapter-details-logs/adapter-details-logs.component';
import { AdapterIntegrationLogsComponent } from './adapters/adapter-integration/adapter-integration-logs/adapter-integration-logs.component';

import {MessageService} from './services/message-service.service';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import {AdapterUserService} from './services/adapter-user.service';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import { RedisDialogComponent } from './adapters/adapter-details/adapter-details-health/redis-dialog/redis-dialog.component';
import { ViewJsonDialogComponent } from './shared/view-json-dialog/view-json-dialog.component';
import { ResetUserJobDialogComponent } from './adapters/adapter-integration/adapter-integration-scheduled-jobs/reset-user-job-dialog/reset-user-job-dialog.component';
import { AdapterIntegrationBatchjobsComponent } from './adapters/adapter-integration/adapter-integration-batchjobs/adapter-integration-batchjobs.component';
import { AdapterIntegrationJobsComponent } from './adapters/adapter-integration/adapter-integration-jobs/adapter-integration-jobs.component';
import { AdapterIntegrationScheduledJobsComponent } from './adapters/adapter-integration/adapter-integration-scheduled-jobs/adapter-integration-scheduled-jobs.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatLegacyChipsModule} from '@angular/material/legacy-chips';
import { AdapterIntegrationQueueListenerComponent} from './adapters/adapter-integration/adapter-integration-queue-listner/adapter-integration-queue-listener.component';

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure({
  ...awsconfig,
  graphql_headers: async () => {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return { Authorization: token }
    }
    catch (e) {
      console.error(e);
      return {};
    }
  }
});

@NgModule({

  declarations: [
    AuthComponent,
    AppComponent,
    HeaderComponent,
    NavComponent,
    AdaptersComponent,
    FooterComponent,
    AdapterDetailsComponent,
    IntegrationsComponent,
    selectedIntegrationDetails$Component,
    AdminUiUsersComponent,
    BreadcrumbComponent,
    AdapterDetailsNavComponent,
    AdapterDetailsHeaderComponent,
    AdapterDetailsHealthComponent,
    AdapterDetailsIntegrationsComponent,
    AdapterIntegrationComponent,
    AdapterIntegrationNavComponent,
    AdapterIntegrationHeaderComponent,
    AdapterIntegrationSchemasComponent,
    AdapterIntegrationStatsComponent,
    AdapterselectedIntegrationDetails$Component,
    DialogBoxComponent,
    AlertDialogBoxComponent,
    AdapterIntegrationUsersComponent,
    ConfirmDialogComponent,
    RawSchemaEntityDialogBodyComponent,
    AttributeCustomizationsDialogBodyComponent,
    JWTInfoDialogComponent,
    ConnectionTestDialogComponent,
    LoglevelDialogComponent,
    AddEditAdminUiUserComponent,
    AdapterCardComponent,
    IntegrationCardComponent,
    AdapterMaintenanceComponent,
    AdapterIntegrationMappingsComponent,
    AuditLogsComponent,
    SecurityComponent,
    SecurityNavComponent,
    AdapterIntegrationQuerytoolComponent,
    PagerComponent,
    AuditlogdialogComponent,
    QueryBuilderDialogComponent,
    QueryBuilderConditionDialogComponent,
    AdapterDetailsConfigComponent,
    ConfigIntegrationTypesComponent,
    ConfigFiltersComponent,
    ConfigEnvironmentsComponent,
    ConfigComponent,
    NavComponent,
    ConfigNavComponent,
    AddEditIntTypeComponent,
    AddEditFilterComponent,
    AddEditEnvironmentComponent,
    AdapterAuditLogComponent,
    AdapterIntegrationAuditLogsComponent,
    ErrorPageComponent,
    AdapterDetailsLogsComponent,
    AdapterIntegrationLogsComponent,
    InputDialogComponent,
    ConfirmationDialogComponent,
    RedisDialogComponent,
    ViewJsonDialogComponent,
    AdapterIntegrationUsersComponent,
    ResetUserJobDialogComponent,
    AdapterIntegrationBatchjobsComponent,
    AdapterIntegrationJobsComponent,
    AdapterIntegrationScheduledJobsComponent,
    CustomToastComponent,
    AdapterIntegrationQueueListenerComponent
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatExpansionModule,
    MatTableModule,
    MatListModule,
    FontAwesomeModule,
    MatIconModule,
    ToastrModule.forRoot({
      positionClass: 'toast-center-center',
      timeOut: 2250,
    }),
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    NgxJsonViewerModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    NgxSpinnerModule,
    MomentModule,
    MatFabMenuModule,
    MatTreeModule,
    NgxDatatableModule,
    FormsModule,
    CodemirrorModule,
    MatChipsModule,
    MatLegacyChipsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
    HealthCheckService,
    AdapterService,
    ScheduledJobAdminService,
    SchemaService,
    AuditTracker,
    LoggingService,
    MessageService,
    AdapterUserService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }