import {Component, OnInit, ViewChild} from '@angular/core';
import {Auth} from 'aws-amplify';
import {Router} from '@angular/router';
import { Hub } from '@aws-amplify/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {GlobalUiStateService} from '../state/global-ui-state.service';
import {GlobalUiStateQuery} from '../state/global-ui-state.query';
import {APIService} from '../API.service';
import {MessageService} from '../services/message-service.service';
import {ServiceEvent} from '../models/service-event';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {InputDialogComponent} from '../components/input-dialog/input-dialog.component';
import {AdapterUser} from '../models/adapter-user.model';
import {AdapterUserService} from '../services/adapter-user.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialogComponent} from '../components/confirmation-dialog/confirmation-dialog.component';
import {CognitoIdentityServiceProvider} from 'aws-sdk';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  userName: string;
  selectedEnv$: string;
  selectedEnvLabel: string;

  showKey = false;
  fullAdminUser;

  constructor(private api: APIService,
              private router: Router,
              public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService,
              private messageService: MessageService,
              public matDialog: MatDialog,
              private adapterUserService: AdapterUserService,
              private toastr: ToastrService
              ) { }

  dropdownEnvSelector() {
    this.trigger.openMenu();
  }

  ngOnInit(): void {

    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (data?.payload?.data?.username) {
        Auth.currentSession().then(async session => {
          this.onAuthEvent(payload);
        });
      }
    });

    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      this.userName = user.attributes.email;
    }).catch(err => console.log(err));

    if (window.location.href.indexOf('toolkitadmin.introhive.com') > -1)  {
      this.selectedEnv$ =  'production';
    }
    else if (window.location.href.indexOf('toolkitadmin-staging.introhive.com') > -1)  {
      this.selectedEnv$ =  'staging';
    }
    else if (window.location.href.indexOf('toolkitadmin-integration.introhive.com') > -1)  {
      this.selectedEnv$ =  'integration';
    }
    else {
      if (this.globalStateQuery.getSelectedEnvironment() == null || this.globalStateQuery.getSelectedEnvironment() === '')
        this.selectedEnv$ =  'dev';
      else
        this.selectedEnv$ =  this.globalStateQuery.getSelectedEnvironment();
    }

    this.globalStateService.updateSelectedEnvironment(this.selectedEnv$);

    this.setSelectedEnvLabel(this.selectedEnv$);

    this.messageService.onChange.subscribe(async item => {
     await this.handleEvent(item);
    } )

  }

  async handleEvent(event: ServiceEvent) {
    const adminUserList = await this.api.ListUsers({emailAddress: {eq: this.userName}});
    if (adminUserList) {
      this.fullAdminUser = await this.api.GetUser(adminUserList.items[0].id);
    }

    this.showKey = event.data["state"] === "Active";

  }

  setSelectedEnvLabel(selectedEnv): void
  {
    if (selectedEnv === "integration")
    {
      this.selectedEnvLabel = "Integration";
    }
    else if (selectedEnv === "staging")
    {
      this.selectedEnvLabel = "Staging";
    }
    else if (selectedEnv === "production")
    {
      this.selectedEnvLabel = "Production";
    }
    else {
      this.selectedEnvLabel = "Development";
    }
  }

  async logOut(): Promise<void> {
    console.log('User is signed out');
    this.showKey = false;
    this.fullAdminUser = null;
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      this.globalStateService.updateInstanceDirect(false);
      this.globalStateService.clearUiState();
      await Auth.signOut({global: true});
      await this.router.navigate(['/login']);
    })
      .catch(err => console.log(err));
  }

  async cancelImpersonation(): Promise<void> {

    this.globalStateService.clearUiState();
    this.globalStateService.updateSelectedImpersonatedEnvPrefix("");
    this.globalStateService.updateSelectedNavPage("adapters");

    // publish a nav change event
    this.messageService.SendEvent({type:"NavUpdate", data:{ }})

    await this.router.navigate(['/adapters'], {
      queryParams: {refresh: new Date().getTime()}
    });
  }

  async login(): Promise<void> {
    this.globalStateService.updateInstanceDirect(false);
    this.globalStateService.clearUiState();
    await this.router.navigate(['/login']);
  }

  async selectedEnvChanged(selectedEnv): Promise<void> {
    this.setSelectedEnvLabel(selectedEnv);
    if (this.globalStateQuery.getSelectedEnvironment() != selectedEnv) {

      this.globalStateService.updateSelectedEnvironment(selectedEnv);
      this.globalStateService.updateSelectedNavPage("adapters");

      const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
      const emailAddress = user.attributes.email;
      const adminUserList = await this.api.ListUsers({ emailAddress: { eq: emailAddress}});
      const fullAdminUser = await this.api.GetUser(adminUserList.items[0].id);
      const envPrefix = selectedEnv === "dev" ? fullAdminUser.userDetail.envPrefix : selectedEnv;
      this.globalStateService.updateSelectedEnvPrefix(envPrefix);

      await this.router.navigate(['/'], {
        queryParams: {refresh: new Date().getTime()}
      });
    }
  }

  onAuthEvent(payload) {

    if (payload.event.indexOf("_failure") > -1 || payload.event.indexOf("signIn") > -1){
      return;
    }

    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (!groups.includes('admin') && !groups.includes('user')) {
        Promise.resolve(Auth.currentAuthenticatedUser())
          .then(user =>
            Auth.currentCredentials()
              .then(credentials =>
                Promise.resolve(
                  new CognitoIdentityServiceProvider({
                    apiVersion: '2016-04-18',
                    credentials: Auth.essentialCredentials(credentials),
                    region: "us-east-1"
                  })
                )
              )
              .then(client =>
                client.adminAddUserToGroup({
                  GroupName: "user",
                  UserPoolId: user.pool.userPoolId,
                  Username: user.username
                }).promise().then(async data => {
                  // now that we know the user is in a group, we can query their user
                  // record from DynamoDB and make sure their username is set
                  const adminUserList = await this.api.ListUsers({emailAddress: {eq: user.attributes.email}});
                  if (adminUserList.items.length > 0) {
                    const adminUser = adminUserList.items[0];

                    await this.updateUsernameForUser(user, adminUser);

                    if (adminUser.role === "admin") {
                      await client.adminAddUserToGroup({
                        GroupName: "admin",
                        UserPoolId: user.pool.userPoolId,
                        Username: user.username
                      }).promise()
                    }
                  }
                })
              )
          );
      } else {
        // now that we know the user is in a group, we can query their user
        // record from DynamoDB and make sure their username is set
        const adminUserList = await this.api.ListUsers({emailAddress: {eq: user.attributes.email}});
        if (adminUserList.items.length > 0) {
          const adminUser = adminUserList.items[0];
          await this.updateUsernameForUser(user, adminUser);
        }
      }
    })
      .catch(err => console.log(err));
  }

  async updateUsernameForUser(user, adminUser) {

    //check to see if the user has their username set - if not, set it and put them in the appropriate group
    if (!adminUser.userDetail.username) {

      try {
        await this.api.UpdateUserDetail({
          id: adminUser.userDetail.id,
          ownerEmail: adminUser.emailAddress,
          name: adminUser.userDetail.name,
          username: user.username,
          envPrefix: adminUser.userDetail.envPrefix,
          hasKeys: adminUser.userDetail.hasKeys
        });
      } catch (e) {
        console.log(e.message);
        return;
      }
    }

  }

  processKeys() {
    if(this.fullAdminUser.userDetail.hasKeys === null || this.fullAdminUser.userDetail.hasKeys === undefined || this.fullAdminUser.userDetail.hasKeys === false)
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data =
        {
          header:"Public Keys",
          message: "Enter your public keys"
        };
      dialogConfig.width = "50%";
      const confirmDialog = this.matDialog.open(InputDialogComponent, dialogConfig);
      confirmDialog.afterClosed().subscribe(async data => {
        var jsonString ="";
        try {
          var keys = data.data;
          if (Array.isArray(keys.keys)) {
            jsonString = JSON.stringify(keys.keys);
          } else {
            jsonString = JSON.stringify(keys);
          }
        }catch(e)
        {
          jsonString = "";
        }
        if(jsonString != undefined && jsonString.trim().length>0)
        {
          var selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
          var user = new AdapterUser();
          user.email = this.userName;
          user.type = "IhUser";
          user.publicKeys =  JSON.parse(jsonString);
          await this.adapterUserService.createUserKeys(selectedAdapterDetails$.endpoint,user).toPromise().then(result => {
            if ( result.status != 201 ) {
              this.toastr.error(`An exception occurred storing keys on the server.`, 'Error!', {
                closeButton: false
              });
              return;
            }
            this.fullAdminUser.userDetail.hasKeys = true;

            var userDetail = {
              id : this.fullAdminUser.userDetail.id,
              ownerEmail: this.fullAdminUser.emailAddress,
              envPrefix: this.fullAdminUser.userDetail.envPrefix,
              name: this.fullAdminUser.userDetail.name,
              username: this.fullAdminUser.userDetail.username,
              hasKeys: true
            };
            this.api.UpdateUserDetail(userDetail);
            this.toastr.success("Successfully added your public keys to the system.","Success");
          }).catch(result=>{
            var error = result.error;
            var msg = result.statusText +" " + (error === undefined ? "" : error.title);
            this.toastr.error(`An exception occurred storing keys on the server.` + msg, 'Error!', {
              closeButton: false
            });
          });

        }
        else if (jsonString === undefined)
        {
          this.toastr.error(`Unable to parse key data to proper Json Format` , 'Error!', {
            closeButton: false
          });
        }
      });
    }
    else{
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: '250px',
        data: {
          dataObj:this.fullAdminUser ,
          title:"Revoke Keys",
          message:"Are you sure you want to revoke your keys?"
        }
      });
      dialogRef.afterClosed().subscribe(async dialogResult => {
        if (dialogResult.response) {
          const adapterStored =  this.globalStateQuery.getSelectedAdapterDetails();// this.adaptersQuery.getEntity(this.adapter.id);
          var user = new AdapterUser();
          user.email = this.fullAdminUser.emailAddress;
          user.type = "IhUser";
          await this.adapterUserService.deleteKeys(adapterStored.endpoint,user).toPromise().then(result => {
            if( result.status == 200 )
            {
              this.fullAdminUser.userDetail.hasKeys = false;
              var userDetail = {
                id: this.fullAdminUser.userDetail.id,
                ownerEmail: this.fullAdminUser.emailAddress,
                name: this.fullAdminUser.userDetail.name,
                username: this.fullAdminUser.userDetail.username,
                envPrefix:this.fullAdminUser.userDetail.envPrefix,
                hasKeys:false
              }
              this.api.UpdateUserDetail(userDetail);
              this.toastr.success('Keys revoked. ', 'Success!', {
                closeButton: true
              });
            }else {
              this.toastr.error('Unable to revoke keys. ', 'Error!', {
                closeButton: true
              });
            }
          });
        }
      });
    }

  }
}