import { Component } from '@angular/core';
import { VERSION } from '../environments/version';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cerebro';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private titleService: Title) {
    console.log(`Application version is: version (from package.json)=${VERSION.version}, git-tag=${VERSION.tag}, git-hash=${VERSION.hash}`);

    this.reset();
  }

  ngOnInit() {
    this.titleService.setTitle("Cerebro");
  }

  reset() {
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}